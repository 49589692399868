import { API_URL } from "../config";
// import axios from "axios";


export const getLeagueList = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/league-list', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getCryptoList = async (token,league_id='') => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let path = '/user/crypto-list';
  if(league_id){
    path+='?league_id='+league_id;
  }
  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getGameList = async (token,league_id='',crypto_id='',user_id='',date='') => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let path = '/user/game-list';
  if(league_id){
    path+='?league_id='+league_id;
  }
  if(crypto_id){
    path+='&crypto_id='+crypto_id;
  }
  if(user_id){
    path+='&user_id='+user_id;
  }
  if(date){
    path+='&date='+date;
  }

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getGameRangeList = async (token,game_id='') => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let path = '/user/range-list';
  if(game_id){
    path+='?game_id='+game_id;
  }

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const joinGame = async (token,game_id='',range_id='') => {
  let data = {
    range_id:range_id,
    game_id:game_id
  }
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(data)
  };
  let path = '/user/joining-game';  

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const updateRange = async (token,game_id='',range_id='',row_id='') => {
  let data = {
    new_prediction_price:range_id,
    game_id:game_id,
    row_id:row_id
  }
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(data)
  };
  let path = '/user/update-prediction';  

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}