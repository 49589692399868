import React, { useContext, useEffect, useState } from 'react';
import Frame from "../components/frame";
import { useNavigate, useParams } from 'react-router-dom';
import { getGameRangeList, joinGame, updateRange } from '../api/league';
import { userInfo } from '../context/AuthContext';
import moment from 'moment/moment';
import toast, { useToasterStore } from 'react-hot-toast';
import Countdown, { zeroPad } from 'react-countdown';
import { MiniChart } from "react-ts-tradingview-widgets";
import { useChannel } from 'ably/react';
import { getMyGames, userDetails } from '../api/auth';
import { BaseCurrencySymbol, CurrencySymbol } from '../utils/constants';
import { IMAGE_URL } from '../config';

var btcprice_change= 0;
var ethprice_change= 0;


class GameDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      select_range_id:null,
      view_chart:false,
      input_amount:'',
      selected_range:null,
      view_leaderboard:false,
      is_duplicate:false
    }
  }

  doJoinLeague = ()=> {
    if(this.props.game_data?.league_id == 1){
      this.doJoinTarget();
    }
    if(this.props.game_data?.league_id == 2){
      this.doJoinPrecision();
    }
  }

  doEditRange = () => {
    this.doEditPriceRange();
  }

  doJoinTarget = async()=>{
    try {
      if(!this.state.select_range_id){
        toast.error('Please select a range!!');
        return false;
      }
      this.setState({loading: true});
      let token = localStorage.getItem('token');
      let response = await joinGame(token,this.props.game_id,this.state.select_range_id);
      if(response.status){
        this.setState({loading: false});
        toast.success(response.msg);
        this.props.getGameDetails();
      }else{
        if(response?.errors?.range_id){
          toast.error(response?.errors?.range_id?.toString());
        }else if(response?.errors?.game_id){
          toast.error(response?.errors?.game_id?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading: false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading: false});
    }
  }

  doJoinPrecision = async()=>{
    try {
      if(!this.state.input_amount){
        toast.error('Please enter amount!!');
        return false;
      }
      this.setState({loading: true});
      let token = localStorage.getItem('token');
      let response = await joinGame(token,this.props.game_id,this.state.input_amount);
      if(response.status){
        this.setState({loading: false,input_amount:''});
        toast.success(response.msg);
        this.props.getGameDetails();
      }else{
        if(response?.errors?.amount){
          toast.error(response?.errors?.amount?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading: false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading: false});
    }
  }

  doEditPriceRange = async()=>{
    try {
      if(!this.state.input_amount){
        toast.error('Please enter amount!!');
        return false;
      }
      this.setState({loading: true});
      let token = localStorage.getItem('token');
      let response = await updateRange(token,this.props.game_id,this.state.input_amount,this.state.selected_range?.row_id);
      if(response.status){
        this.setState({loading: false,input_amount:'',selected_range:null});
        toast.success(response.msg);
        this.props.getGameDetails();
      }else{
        if(response?.errors?.amount){
          toast.error(response?.errors?.amount?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading: false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading: false});
    }
  }

  renderer = ({  hours, minutes, seconds, completed }) => {    
    let text = '';
    if(this.props.game_data?.status==0){
      text = 'Starts in ';
    }else if(this.props.game_data?.status==1){
      text = 'Live ';
    }else{
      text = 'Ended ';
    }

    if(this.props.game_joined){
      return <span> {text} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} | JOINED</span>;
    }else{
      return <span> {text} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };

  renderer2 = ({ hours, minutes, seconds, completed }) => {    
    return <div className='count_down_box'>
      
      <span>{zeroPad(hours)} <p>Hours</p></span> 
      <span>{zeroPad(minutes)} <p>Mins</p></span>
      <span>{zeroPad(seconds)} <p>Secs</p></span></div>;
  };

  render() {
    let this2 = this;
    let game_start_t = this.props?.game_data?.start_at;
    let game_start_local_t = moment.utc(game_start_t).toDate();
    let game_start_view = moment(game_start_local_t).format('LLL');      
    let game_end = this.props?.game_data?.end_at;
    let game_end_local = moment.utc(game_end).toDate();
    let game_end_view = moment(game_end_local).format('LLL');
    let current_price = this.props?.game_data?.initial_price||0;
    if(this.props.game_data?.crypto_id == 1){
      current_price = this.props.btcprice?this.props.btcprice:this.props.game_data?.initial_price;
    }
    if(this.props.game_data?.crypto_id == 2){
      current_price = this.props.ethprice?this.props.ethprice:this.props.game_data?.initial_price;
    }
    if(this.props.game_data?.status == 4){
      current_price = this.props.game_data?.end_price;
    }

    let no_of_winner = 1;
    let win_status = false;
    if(this.props.game_data?.league_id == 2 && this.props.game_data?.status == 4){
      // if(this.props.my_range_list?.length>0){
        // let win_c = this.props.range_list.filter(function (el) {
        //   return el.range_id == this2.props.range_list[0]?.range_id;
        // });
        // no_of_winner = win_c.length;
        // let found_w = this.props.my_range_list.find((element) => element.range_id == this2.props.range_list[0]?.range_id);
        // if(found_w){
        //   win_status=true;
        // }
        
      // }   
      if(this.props.won_prize>0){
        win_status=true;
      }   
    }

    let range_data = [];
    if(this.props.game_joined && this.props.game_data?.league_id==2){
      range_data = this.props.game_joined?.range_string?.split(',');
    }

    if(this.props.is_joined && this.props.game_data?.league_id==2){
      range_data = this.props.user_game_data?.string?.split(',');
    }

    return (
      <Frame withHeader={false} withFooter={true} current_page={'wallet'}>
        {this.props.is_fetching || this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        
        <div className="pt-0 pb-120">
          <div className="header absolute">
            <div className="tf-container">
                <div className="d-flex justify-content-between align-items-center mt-12">
                    <a onClick={()=>this.props.navigate(-1)} className="box-icon back-btn"><i className="icon-left-btn"></i></a>
                </div>
            </div>
          </div>
          <div className="Leagues-blog position-relative">
            {this.props.game_data?.league_id==1?(
              <>
                <img src="/assets/img/target-Leagues-bg.png" alt="images" className="Leagues-bg" />
                <img src="/assets/img/target-Leagues-logo.png" alt="images" className="Leagues-logo" />
              </>
            ):(null)}
            {this.props.game_data?.league_id==2?(
              <>
                <img src="/assets/img/Prediction-Leagues-bg.png" alt="images" className="Leagues-bg" />
                <img src="/assets/img/Prediction-Leagues-logo.png" alt="images" className="Leagues-logo" />
              </>
            ):(null)}
              
          </div>
          <div className="position-relative">

            <div className="pt-20 pb-10">              
              {this.props.game_data?.status==0||this.props.game_data?.status==1?(
                <div className="tf-container">                
                  <div className="leauges_game_box">
                    <div className="starts_box">
                      {this.props.game_data?.status==0?(
                        <Countdown intervalDelay={0}
                          precision={3} date={Date.now()+this.props.duration}
                          renderer={this.renderer}
                          onComplete={()=>this.props.updateGameStatus()}
                        />
                      ):(null)}                      
                    </div>
                    <h6 className='d-flex align-items-center justify-content-between'>{this.props.current_crypto?.name}
                      {this.props.game_joined?(
                        this.props.game_data?.league_id==1?(
                          <span className='game_selected'> You Selected {this.props.game_joined?.range_string}</span>
                        ):(
                          <div className="dropdown">
                            <button type="button" className="dropdown-toggle btn_shadow_text" data-bs-toggle="dropdown">
                              Predictions {range_data[0]}
                            </button>
                            <div className="dropdown-menu p-2 btn_shadow_text_body">
                              {range_data.map(function(value){
                                return <span>{value}</span>
                              })}
                              {/* {this.props.game_joined?.range_string} */}
                            </div>
                          </div> 
                         
                        )
                      ):(null)}
                    </h6>
                    {this.props.game_data?.crypto_id == 1?(
                      <h4>{this.props.btcprice?this.props.btcprice:this.props.game_data?.initial_price} {BaseCurrencySymbol}
                        {this.props.btcprice_change>=0?(
                          <small className="ml_10 text-success"><i className="icon-select-up"></i> {this.props.btcprice_change}%</small>
                        ):(
                          <small className="ml_10 text-danger"><i className="icon-select-down"></i> {this.props.btcprice_change}%</small>
                        )}
                      </h4>
                    ):(
                      <h4>{this.props.ethprice?this.props.ethprice:this.props.game_data?.initial_price} {BaseCurrencySymbol}
                        {this.props.ethprice_change>=0?(
                          <small className="ml_10 text-success"><i className="icon-select-up"></i> {this.props.ethprice_change}%</small>
                        ):(
                          <small className="ml_10 text-danger"><i className="icon-select-down"></i> {this.props.ethprice_change}%</small>
                        )}
                      </h4>
                    )} 
                    <ul>
                      <li>
                        <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
                        <p>Prize Pool</p>
                      </li>
                      <li>
                        <h6>{this.props.game_data?.player}</h6>
                        <p>Players</p>
                      </li>
                      <li>
                        <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
                        <p>duration</p>
                      </li>
                      <li>
                        <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
                        <p>entry fee</p>
                      </li>
                    </ul>
                    {this.props.game_data?.league_id==2?(
                      <p className='text-center no_of_win'>Number of winner : {this.props.total_winner_count}</p>
                    ):(null)}
                    {this.props.game_data?.status==1?(
                      <div className='mt-10'>
                        <Countdown intervalDelay={0}
                          precision={3} date={Date.now()+this.props.duration}
                          renderer={this.renderer2}
                          onComplete={()=>this.props.updateGameStatus()}
                        />
                      </div>
                    ):(null)}
                  </div>

                  {this.props.game_data?.league_id==2 && this2.props.game_data?.status==0?(
                    <div className="mb-18 mt-10">
                      <h4 className="mb-8 text-center"> Predict The Price</h4>
                      <input className="input_area" type="text" placeholder="Amount"
                        onChange={(e)=>this.setState({input_amount:e.target.value},()=>{
                          if(this2.state.input_amount){
                            let found_w = this.props.range_list.find((element) => element.range_id == this2.state.input_amount);
                            if(found_w){
                              this2.setState({is_duplicate:true});
                            }else{
                              this2.setState({is_duplicate:false});
                            }
                          }
                        })}
                        value={this.state.input_amount}
                      />
                    </div>
                  ):(null)}

                  <p className='view_chart' onClick={()=>this2.setState({view_chart:!this2.state.view_chart})}>View Chart</p>
                  {this.state.view_chart?(
                    <MiniChart chartOnly={false} symbol={`${this2.props.current_crypto?.code}${BaseCurrencySymbol}`} dateRange='1D' isTransparent={true} colorTheme="dark" width="100%"></MiniChart>
                  ):(null)}
                  
                  {this.state.is_duplicate?(
                    <p className='text-error text-center'>Prediction point already on leaderboard</p>
                  ):(null)}
                  <ul className={this.props.range_list?.length>0?"game_balance_box":""} style={{marginTop:this.state.view_chart?20:0}}>
                    {this.props.game_data?.league_id==1 && this.props.range_list?.length>0?(                    
                      this.props.range_list?.map(function(item,index){
                        let active_class = '';
                        if(this2.props.game_data?.status==0){
                          if(this2.state.select_range_id==item.id){
                            active_class='active_range';
                          }
                        }
                        if(this2.props.game_data?.status==0 && item.is_joined){
                          active_class='active_range';
                        }
                        if(this2.props.game_data?.status==1){
                          let c_price = this2.props.game_data?.crypto_id == 1?this2.props.btcprice:this2.props.ethprice;
                          let value_from = item.value_from;
                          let value_to = item.value_to;
                          if(value_from==null && value_to){
                            if(parseFloat(c_price)>=parseFloat(value_to) && item.operator=='>='){
                              active_class='active_border';
                            }
                            if(parseFloat(c_price)<parseFloat(value_to) && item.operator=='<'){
                              active_class='active_border';
                            }
                          }
                          if(value_from && value_to && item.operator=='-'){
                            if(parseFloat(c_price)>parseFloat(value_from) && parseFloat(c_price)<parseFloat(value_to)){
                              active_class='active_border';
                            }
                          }                          
                        }
                        return(
                          <li key={`rng${index}`}
                          onClick={()=>{
                            if(this2.props.game_data?.status==0 && this2.props.game_data?.is_joined==null){
                              this2.setState({select_range_id:item.id})
                            }
                          }}
                          className={active_class}
                          style={{color:'#fff'}}
                          >{item.string}
                            {((active_class=='active_range' || this2.props.game_data?.status==1) && item.is_joined) && 
                            (this2.props.userDetails?.avatar?(
                              <img style={{marginLeft:10}} className='avatar_view_sm2' src={IMAGE_URL+this2.props.userDetails?.avatar} alt="img" />
                            ):(
                              <img style={{marginLeft:10}} className='avatar_view_sm2' src="/assets/img/avt2.png" alt="img" />
                            ))
                            }
                            <span> {item.prize_pool} {CurrencySymbol}</span>
                            <span className='mr_10'> <i className='icon-user'></i> {item.players} </span>
                          </li>
                        )
                      })
                    ):(null)}

                    {this.props.game_data?.league_id==2 && this.props.range_list?.length>0?(
                      this.props.range_list?.map(function(item,index){
                        let active_class = '';
                        let active_bg = '';
                        let won_class = '';
                        let price_diff=parseFloat(item.range_id-current_price).toFixed(2);
                        let percent = parseFloat(price_diff/parseFloat(item.range_id)*100).toFixed(2);
                        if(price_diff>=0){                          
                          active_class='text-success';
                        }else{
                          price_diff=parseFloat(current_price-item.range_id).toFixed(2);
                          percent = parseFloat(price_diff/parseFloat(item.range_id)*100).toFixed(2);
                          active_class='text-error';
                        }
                        if(this2.state.selected_range){
                          if(item.range_id == this2.state.selected_range?.range_id){
                            active_bg='active_bg';
                          }
                        }
                        if(parseFloat(item.rank)<=this2.props.total_winner_count){
                          won_class = 'active_border_bg'
                        }

                        return(
                          <li key={`rng${index}`}
                            onClick={()=>{
                              if(this2.props.game_data?.status==0 && this2.props.userDetails?.username==item.username){
                                if(this2.state.selected_range){
                                  if(this2.state.selected_range.range_id==item.range_id){
                                    this2.setState({
                                      selected_range:null,
                                      input_amount:''
                                    });
                                  }else{
                                    this2.setState({
                                      selected_range:item,
                                      input_amount:item.range_id
                                    });
                                  }                                  
                                }else{
                                  this2.setState({
                                    selected_range:item,
                                    input_amount:item.range_id
                                  });
                                }
                                
                              }
                            }}
                            className={`${active_bg} ${won_class}`}
                            style={{color:'#fff'}}
                            >
                            {item.rank}
                            {/* {index+1} */}
                            {item?.avatar?(
                              <img style={{marginRight:10,marginLeft:10}} className='avatar_view_sm2' src={IMAGE_URL+item?.avatar} alt="img" />
                            ):(
                              <img style={{marginRight:10,marginLeft:10}} className='avatar_view_sm2' src="/assets/img/avt2.png" alt="img" />
                            )}
                            {this2.props.userDetails?.username!=item.username ? item.username : 'You'}                            
                            
                            <span className={`${active_class}`}>  {price_diff}({percent}%) <span className='e1_top_position'>{item.t_name}</span></span>
                            <span className='mr_20'> {item.range_id} {BaseCurrencySymbol}</span>
                          </li>
                        )
                      })
                    ):(null)}

                  </ul>

                  {this.props.game_data?.league_id==1 && this.props.game_data?.status==0 && this.props.game_data?.is_joined==null?(
                    <div className='bottom_fixed'>
                      <button className='tf-btn primary md mt-30' onClick={this.doJoinLeague}>Join Now</button>
                    </div>
                  ):(null)}
                  
                  {this.props.game_data?.league_id==2 && this.props.game_data?.status==0?(
                    this2.state.selected_range?(
                      this2.state.selected_range?.range_id==this2.state.input_amount || !this2.state.input_amount?(
                        <div className='bottom_fixed'>
                          <button className={`tf-btn primary md mt-30 disable_bg`}>Edit</button>
                        </div>
                      ):(
                        <div className='bottom_fixed'>
                          <button className={`tf-btn primary md mt-30`} onClick={this.doEditRange}>Edit</button>
                        </div>
                      )                      
                    ):(
                      this2.state.is_duplicate?(
                        <div className='bottom_fixed'>
                          <button className='tf-btn primary md mt-30 disable_bg'>Join Now</button>
                        </div>
                      ):(
                        <div className='bottom_fixed'>
                          <button className='tf-btn primary md mt-30' onClick={this.doJoinLeague}>Join Now</button>
                        </div>
                      )                      
                    )
                  ):(null)}
                </div>
              ):(
                <div className="tf-container"> 
                  {this.props.game_data?.status==2?(
                    <div className="search-result text-center">
                      <img src="/assets/img/search.png" alt="" />
                      <p className="mb-0 text-warning">Processing...</p>
                      <div>Results for this league are being processed. It can take up to a few minutes to complete.</div>
                    </div>
                  ):(null)}
                  {this.props.game_data?.status==3?(
                    <div className="search-result">                      
                      <div>
                        <h4 className="mb-2 colorprimary mt-3">Game abandoned!!</h4>
                        {this.props.game_data?.player>0?(
                          <div>Entry fee refunded.</div>
                        ):(null)}
                      </div>
                      {this.props.game_data?.crypto_id==1?(
                        <button type="button" className="btn-new new-outerss"><img src="/assets/img/bitcoin.png"/>Bitcoin</button>
                      ):(
                        <button type="button" className="btn-new new-outerss"><img  src="/assets/img/etherium.png"/>Etherium</button>
                      )}

                      <div className="leauges_game_box mt-20">
                        <ul>
                          <li>
                            <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
                            <p>Prize Pool</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.player}</h6>
                            <p>Players</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
                            <p>duration</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
                            <p>entry fee</p>
                          </li>
                        </ul>
                        <div className="crypto_shadow_box mt-3 mb-2">
                          <div className="box-inner">
                            <div className="start-time">Start Time</div>
                            <div className="date">---</div>
                          </div>
                          <div className="box-inner">
                            <div className="start-time">End Time</div>
                            <div className="date">---</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ):(null)}
                  {this.props.game_data?.status==4 && this.props.game_data?.league_id==1?(
                    <div className="search-result">
                      {this.props.game_data?.winner_range_id == this.props.game_data?.is_joined ? (
                        <div className="box_bg new text-center">
                          <div className="profile_edit">
                            <img src="/assets/img/victory.png" alt="img" />
                          </div>
                          <h4 className="mb-2 colorprimary mt-3">Victory!!</h4>
                          <p className='new-inner'>You won {parseFloat(this.props.game_data?.prize_pool/this.props.winner_data?.players).toFixed(2)} {CurrencySymbol}</p>
                        </div>
                      ):(null)}
                      {!this.props.game_data?.winner_range_id? (
                        <div className="box_bg new text-center">
                          <h3></h3>
                          <p>No winner Available.</p>
                        </div>
                      ):(null)}
                      {!this.props.game_data?.is_joined? (
                        <div className="box_bg new text-center">
                          <h3></h3>
                          <p>You did not join this game.</p>
                        </div>
                      ):(null)}
                      {this.props.game_data?.is_joined && this.props.game_data?.winner_range_id &&
                      this.props.game_data?.winner_range_id != this.props.game_data?.is_joined? (
                        <div className="box_bg new text-center">
                          <div className="profile_edit">
                            <img src="/assets/img/oops.png" alt="img" />
                          </div>
                          <h4 className='mb-2 color-error mt-3'>Oops!!</h4>
                          <p>Better Luck Next Time !</p>
                        </div>
                      ):(null)}
                      {this.props.game_data?.is_joined?(
                        this.props.game_data?.league_id==1?(
                          <span className='game_selected'> You Selected {this.props.user_game_data?.string}</span>
                        ):(
                          <div className="dropdown">
                            <button type="button" className="dropdown-toggle btn_shadow_text" data-bs-toggle="dropdown">
                              Predictions {range_data[0]}
                            </button>
                            <div className="dropdown-menu p-2 btn_shadow_text_body">
                              {range_data.map(function(value){
                                return <span>{value}</span>
                              })}
                              {/* {this.props.user_game_data?.string} */}
                            </div>
                          </div>                         
                        )
                        // <span className='game_selected text-center' style={{float:'none'}}> You Selected {this.props.user_game_data?.string}</span>
                      ):(null)}
                      {this.props.game_data?.crypto_id==1?(
                        <button type="button" className="btn-new new-outerss"><img src="/assets/img/bitcoin.png"/>Bitcoin</button>
                      ):(
                        <button type="button" className="btn-new new-outerss"><img src="/assets/img/etherium.png"/>Etherium</button>
                      )}
                       <div className="leauges_game_box mt-20">
                          <ul>
                            <li>
                              <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
                              <p>Prize Pool</p>
                            </li>
                            <li>
                              <h6>{this.props.game_data?.player}</h6>
                              <p>Players</p>
                            </li>
                            <li>
                              <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
                              <p>duration</p>
                            </li>
                            <li>
                              <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
                              <p>entry fee</p>
                            </li>
                          </ul>
                          <div className="crypto_shadow_box mt-3 mb-2">
                            <div className="box-inner">
                              <div className="start-time">Start Time</div>
                              <div className="date">{game_start_view}</div>
                            </div>
                            <div className="box-inner">
                              <div className="start-time">End Time</div>
                              <div className="date">{game_end_view}</div>
                            </div>
                            <hr className='mt-2 mb-2' />
                            <div className="box-inner">
                              <div className="start-time">End Price</div>
                              <div className="date">{this.props.game_data?.end_price} {BaseCurrencySymbol}</div>
                            </div>
                            {this.props.game_data?.winner_range_id? (
                              <div className="box-inner">
                                <div className="start-time">Winning Target</div>
                                <div className="date">{this.props.winner_data?.string}</div>
                              </div>
                            ):(null)}
                            {/* {this.props.game_data?.winner_range_id == this.props.game_data?.is_joined?(
                              <div className="box-inner">
                                <div className="start-time">Your Target</div>
                                <div className="date">{this.props.winner_data?.user_target_view}</div>
                              </div>
                            ):(null)} */}
                            {this.props.winner_data?.players?(
                              <div className="box-inner">
                                <div className="start-time">No of winner Target</div>
                                <div className="date">{this.props.winner_data?.players}</div>
                              </div>                            
                            ):(null)}
                          </div>
                        </div>
                      
                    </div>
                  ):(null)}

                  {this.props.game_data?.status==4 && this.props.game_data?.league_id==2?(
                    <div className="search-result">
                      {win_status ? (
                        <div className="box_bg new text-center">
                          <div className="profile_edit">
                            <img src="/assets/img/victory.png" alt="img" />
                          </div>
                          <h4 className="mb-2 colorprimary mt-3">Victory!!</h4>
                          <p className='new-inner'>You won {parseFloat(this.props.won_prize).toFixed(2)} {CurrencySymbol}</p>
                        </div>
                      ):(null)}
                      
                      {!this.props.game_data?.is_joined? (
                        <div className="box_bg new text-center">
                          <h3></h3>
                          <p>You did not join this game.</p>
                        </div>
                      ):(null)}
                      {this.props.game_data?.is_joined && !win_status? (
                        <div className="box_bg new text-center">
                          <div className="profile_edit">
                            <img src="/assets/img/oops.png" alt="img" />
                          </div>
                          <h4 className='mb-2 color-error mt-3'>Oops!!</h4>
                          <p>Better Luck Next Time !</p>
                        </div>
                      ):(null)}
                      {/* {this.props.game_data?.is_joined?(
                        <span className='game_selected text-center' style={{float:'none'}}> You Selected {this.props.user_game_data?.string}</span>
                      ):(null)} */}
                      {this.props.game_data?.crypto_id==1?(
                        <button type="button" className="btn-new new-outerss"><img src="/assets/img/bitcoin.png"/>Bitcoin</button>
                      ):(
                        <button type="button" className="btn-new new-outerss"><img src="/assets/img/etherium.png"/>Etherium</button>
                      )}
                       <div className="leauges_game_box mt-20">
                        <ul>
                          <li>
                            <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
                            <p>Prize Pool</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.player}</h6>
                            <p>Players</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
                            <p>duration</p>
                          </li>
                          <li>
                            <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
                            <p>entry fee</p>
                          </li>
                        </ul>
                        {this.props.game_data?.league_id==2?(
                          <p className='text-center no_of_win'>Number of winner : {this.props.total_winner_count}</p>
                        ):(null)}
                        <div className="crypto_shadow_box mt-3 mb-2">
                          <div className="box-inner">
                            <div className="start-time">Start Time</div>
                            <div className="date">{game_start_view}</div>
                          </div>
                          <div className="box-inner">
                            <div className="start-time">End Time</div>
                            <div className="date">{game_end_view}</div>
                          </div>
                          <hr className='mt-2 mb-2' />
                          <div className="box-inner">
                            <div className="start-time">End Price</div>
                            <div className="date">{this.props.game_data?.end_price} {BaseCurrencySymbol}</div>
                          </div>
                          {/* {this.props.game_data?.winner_range_id? (
                            <div className="box-inner">
                              <div className="start-time">Winning Target</div>
                              <div className="date">{this.props.game_data?.winner_range_id} {BaseCurrencySymbol}</div>
                            </div>
                          ):(null)} */}
                          
                          {/* {this.props.winner_data?.players?(
                            <div className="box-inner">
                              <div className="start-time">No of winner Target</div>
                              <div className="date">{this.props.winner_data?.players}</div>
                            </div>                            
                          ):(null)} */}

                          {this.props.game_data?.is_joined? (
                            <>
                              <hr className='mt-2 mb-2' />
                              <div className="box-inner">
                                <div className="start-time">Predicted Price</div>
                                <div className="start-time">Delta</div>
                                <div className="start-time">Rank</div>
                              </div>
                            </>
                          ):(null)}

                          {this.props.my_range_list.length>0?(
                            this.props.my_range_list.map(function(item,i){
                              let active_class = '';
                              let price_diff=parseFloat(current_price-item.range_id).toFixed(2);
                              
                              return (
                                <div className="box-inner" key={`ppp-${i}`}>
                                  <div className="start-time">{item.range_id} {BaseCurrencySymbol}</div>
                                  <div className="start-time"><span className={`${active_class}`}>{price_diff} </span></div>
                                  <div className="start-time">{item.rank}</div>
                                </div>
                              )
                            })
                          ):(null)}
                        </div>
                      </div>

                      <div className="mt-20">
                        {/* <br />
                        <div className='winner_box'>
                          <h5 className='mt-10'>No 1</h5>
                          {this.props.winner_data?.avatar?(
                              <img src={IMAGE_URL+this.props.winner_data?.avatar} alt="img" />
                            ):(
                              <img src="/assets/img/avt2.png" alt="img" />
                            )}
                            <h5>{this.props.winner_data?.username}</h5>
                            <h4 className='mb-0'> {this.props.range_list[0]?.price_diff}</h4>
                        </div> */}

                       <div>
                        <p className='view_chart mt-20'  data-bs-toggle="modal" data-bs-target="#winner_details">View Leaderboard</p>
                       
                      </div>


                      <div className="modal fade modalRight" id="winner_details">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                              <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                              <h4 className="mb-0">Winner</h4>
                            </div>
                      
                            <div className="overflow-auto pt-45 pb-16">
                              <div className="tf-container">
                                {/* <div className='winner_box'>
                                  <h5 className='mt-10'>No 1</h5>
                                  {this.props.winner_data?.avatar?(
                                      <img src={IMAGE_URL+this.props.winner_data?.avatar} alt="img" />
                                    ):(
                                      <img src="/assets/img/avt2.png" alt="img" />
                                    )}
                                    <h5>{this.props.winner_data?.username}</h5>
                                    <h4 className='mb-0'> {this.props.range_list[0]?.price_diff}</h4>
                                </div> 
                                <br />*/}
                                <div className="box-inner">
                                  <div className="start-time width_50">Rank</div>
                                  <div className="start-time width_25 text-center">Predicted Price</div>
                                  <div className="start-time width_25 text-end">Delta</div>
                                </div>
                                {/* {this.state.view_leaderboard?( */}
                                  <ul className={this.props.range_list?.length>0?"modal_winner_box":""}>
                                  {this.props.game_data?.league_id==2 && this.props.range_list?.length>0?(
                                    this.props.range_list?.map(function(item,index){
                                      let active_class = '';
                                      let won_class = '';
                                      let price_diff=parseFloat(item.range_id-current_price).toFixed(2);
                                      let percent = parseFloat(price_diff/parseFloat(item.range_id)*100).toFixed(2);
                                      if(price_diff>=0){                          
                                        active_class='text-success1';
                                      }else{
                                        price_diff=parseFloat(current_price-item.range_id).toFixed(2);
                                        percent = parseFloat(price_diff/parseFloat(item.range_id)*100).toFixed(2);
                                        active_class='text-error1';
                                      }

                                      if(parseFloat(item.prize)>0){
                                        won_class = 'active_border_bg'
                                      }

                                      return(
                                        <li key={`rng${index}`}
                                        onClick={()=>{
                                          if(this2.props.game_data?.status==0 && this2.props.game_data?.is_joined==null){
                                            this2.setState({select_range_id:item.id})
                                          }
                                        }}
                                        className={won_class}
                                        >
                                          <div className='width_50 d-flex align-items-center'>
                                          <h5>{item?.rank}</h5>
                                          {item?.avatar?(
                                            <img className='avatar_view_sm2' src={IMAGE_URL+item?.avatar} alt="img" />
                                          ):(
                                            <img className='avatar_view_sm2' src="/assets/img/avt2.png" alt="img" />
                                          )}
                                          <div className='ms-2'>
                                            <p className='mb-0'>{this2.props.userDetails?.username!=item.username ? item.username : 'You'}</p> 
                                            {parseFloat(item.prize)>0?(
                                              <p className='text-success mb-0'> Won {parseFloat(item.prize).toFixed(2)} {CurrencySymbol}</p>
                                            ):(null)}
                                          </div>
                                          </div>
                                          <div className='width_25 text-center'>{item.range_id} {BaseCurrencySymbol}</div>
                                          <div className={`width_25 ${active_class} text-end`}>  {price_diff}
                                            {/* ({percent}%)  */}

                                          </div>
                                          {/* <span className='mr_20'> {item.range_id} {BaseCurrencySymbol}</span> */}
                                        </li>
                                      )
                                    })
                                  ):(null)}
                                </ul>
                                {/* ):(null)}  */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>






                      </div>
                      
                    </div>
                  ):(null)}
                </div>
              )}
            </div>
          </div>
        </div>
      </Frame>
    );
  }
}

function GameDetailsHOC(props) {
  let { game_id } = useParams();
  const { eth_price, btc_price, eth_lastPrice, btc_lastPrice,
    current_crypto, current_btc_price, current_eth_price,
    league_crypto_list,setLeagueCryptoList,
    my_games, setMyGames } = useContext(userInfo);
  const [range_list, setRangeList] = useState([]);
  const [game_details, setGameDetails] = useState(null);
  const [is_fetching, setFetching] = useState(true);
  const [load_data,setLoadData] = useState(true);
  const [winner_data, setWinnerData] = useState(null);
  const [user_game_data, setUserGameData] = useState(null);
  const [game_status_update, setGameStatusUpdate] = useState(null);
  const [end_time, setEndTime] = useState(0);
  const [user_Details,setUserDetails] = useState(null);
  const [total_winner_count,settotal_winner_count] = useState(0);
  const navigate = useNavigate();
  if (!game_id) {
    navigate('/')
  }  

  let game_joined = null;
  if(my_games.length>0){
    let found = my_games.find((element) => element.game_id == game_id);
    if(found){
      game_joined = found;
    }
  }

  useEffect(() => {
    (async () => {
      setLoadData(false);
      if(is_fetching){
        getGameDetails();
        let token = localStorage.getItem('token');
        let user_response = await userDetails(token);
        if(user_response.status){
          setUserDetails(user_response?.data);
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };

  }, [load_data,range_list, game_details, game_status_update,winner_data,user_game_data]);

  const getGameDetails = async()=>{
    try {
      let token = localStorage.getItem('token');
      let game_range_list = await getGameRangeList(token, game_id);
      if (game_range_list.status) {
        let dd = game_range_list?.data;
        if(game_range_list?.game_detail?.league_id==1){
          dd.reverse();
        }        
        setRangeList(dd);
        setGameDetails(game_range_list?.game_detail);
        settotal_winner_count(game_range_list?.total_winner_count);
        let game_start = game_range_list?.game_detail?.start_at;
        let game_start_local = moment.utc(game_start).toDate();
        let game_start_timestap = moment(game_start_local).format('X');
        let end_time_t = parseInt(game_start_timestap);
        if(game_range_list?.game_detail?.status==1){
          end_time_t = end_time_t + parseInt(game_range_list?.game_detail?.duration_in_minitue)*60;
        }
        end_time_t = end_time_t*1000;
        setEndTime(end_time_t);
        let current_time = Date.now();
      
        if (current_time>end_time_t && game_range_list?.game_detail?.status==0) {
          // navigate('/')
        }

        if (current_time>end_time_t && game_range_list?.game_detail?.status==1) {
          // navigate('/')
        }
      }
      let current_date = Date.now();
      current_date = moment(current_date).format('YYYY-MM-DD');
      let my_game_list = await getMyGames(token,current_date);
      if(my_game_list.status){
        setMyGames(my_game_list?.data);
      }

      if(game_range_list?.game_detail?.league_id == 1){
        if(game_range_list?.game_detail?.winner_range_id){
          let found = game_range_list?.data.find((element) => element.id == game_range_list?.game_detail?.winner_range_id);
          if(found){
            let game_start = game_range_list?.game_detail?.start_at;
            let game_start_local = moment.utc(game_start).toDate();
            let game_start_view = moment(game_start_local).format('LLL');
            found.game_start_view = game_start_view;
            let game_end = game_range_list?.game_detail?.end_at;
            let game_end_local = moment.utc(game_end).toDate();
            let game_end_view = moment(game_end_local).format('LLL');
            found.game_end_view = game_end_view;
            found.user_target_view = null;
            let found_u = game_range_list?.data.find((element) => element.id == game_range_list?.game_detail?.winner_range_id);
            if(found_u){
              found.user_target_view = found_u?.string;
            }
            setWinnerData(found);
          }
        }
        if(game_range_list?.game_detail?.is_joined){
          let found = game_range_list?.data.find((element) => element.id == game_range_list?.game_detail?.is_joined);
          if(found){          
            setUserGameData(found);
          }
        }
      }

      if(game_range_list?.game_detail?.league_id == 2){
        if(game_range_list?.game_detail?.winner_range_id){
          let found = game_range_list?.data.find((element) => element.range_id == game_range_list?.game_detail?.winner_range_id);
          if(found){
            let game_start = game_range_list?.game_detail?.start_at;
            let game_start_local = moment.utc(game_start).toDate();
            let game_start_view = moment(game_start_local).format('LLL');
            found.game_start_view = game_start_view;
            let game_end = game_range_list?.game_detail?.end_at;
            let game_end_local = moment.utc(game_end).toDate();
            let game_end_view = moment(game_end_local).format('LLL');
            found.game_end_view = game_end_view;
            found.user_target_view = null;
            let found_u = game_range_list?.data.find((element) => element.range_id == game_range_list?.game_detail?.winner_range_id);
            if(found_u){
              found.user_target_view = found_u?.range_id;
            }
            setWinnerData(found);
          }
        }
        if(game_range_list?.game_detail?.is_joined){
          let found = game_range_list?.data.find((element) => element.range_id == game_range_list?.game_detail?.is_joined);
          if(found){          
            setUserGameData(found);
          }
        }
      }


      setFetching(false);
    } catch (error) {
      setFetching(false);
    }    
    
  }

  const updateGameStatus = ()=>{
    let tt = game_details;
    if(tt.status == 0){
      if(game_status_update){
        tt.status = game_status_update.game_status;
        let game_start = tt?.start_at;
        let game_start_local = moment.utc(game_start).toDate();
        let game_start_timestap = moment(game_start_local).format('X');
        let end_time_t = parseInt(game_start_timestap);
        end_time_t = end_time_t + parseInt(tt?.duration_in_minitue)*60;
        end_time_t = end_time_t*1000;
        setEndTime(end_time_t);
        setGameDetails(tt);
      }else{
        tt.status=3;
        setGameDetails(tt);
      }      
    }else if(tt.status == 1){
      tt.status=2;
      setGameDetails(tt);
    }
  }

  useChannel('game-list', 'prize-pool', (message) => {
    console.log('prize-pool',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let found = list.find((element) => element.game_id == game_id);
      if(found){
        let temp_range_list = [];
        let temp_game_details = game_details;
        temp_game_details.player = found.players;
        temp_game_details.prize_pool = found.prize_pool;
        let lts_rng = found.ranges_detail;
        if(game_details?.league_id==1){
          range_list.map(function(item){
            let dt = item;
            let foundr = lts_rng.find((element) => element.range_id == item.id);
            dt.players = foundr.players;
            dt.prize_pool = foundr.prize_pool;
            temp_range_list.push(dt);
          });
          setRangeList(temp_range_list);
        }
        if(game_details?.league_id==2){
          let temp_range_list=[];
          if(game_details?.league_id==2){
            let cm_price=game_details?.initial_price;
            if(game_details?.crypto_id==1){
              cm_price = parseFloat(btc_price);
            }
            if(game_details?.crypto_id==2){
              cm_price = parseFloat(eth_price);
            }
            if(game_details?.status==4){
              cm_price = parseFloat(game_details?.end_price);
            }

            lts_rng.map(function(item){
              let dt = item;
              let price_diff=parseFloat(parseFloat(item.range_id)-cm_price).toFixed(2);
              if(price_diff<0){
                price_diff=parseFloat(cm_price-parseFloat(item.range_id)).toFixed(2);
              }
              dt.price_diff=parseFloat(price_diff);
              temp_range_list.push(dt);
            });
            //if(game_details?.status==4){
              temp_range_list.sort(function(a,b){ return parseFloat(a.price_diff) - parseFloat(b.price_diff); });
            //}
            let temp_range_list_d = temp_range_list.map(function(item,i){
              let data = item;
              data.rank = i+1;
              return data;
            });
            setRangeList(temp_range_list_d);
            settotal_winner_count(found?.total_winner_count);
          }
        }
        
        setGameDetails(temp_game_details);
        let temp_status = {
          game_id:found?.game_id,
          game_status:found?.game_status
        }
        console.log('temp_status',temp_status);
        setGameStatusUpdate(temp_status);
      }
    }

  });

  useChannel('game-list', 'live', (message) => {
    let list = JSON.parse(message?.data);
    // console.log('live',JSON.parse(message?.data));
    if(list.length>0){
      let found = list.find((element) => element.id == game_id);
      if(found){
        let temp_game_details = game_details;
        temp_game_details.status = found.status;
        temp_game_details.start_at = found.start_at;
        temp_game_details.end_at = found.end_at;
        // console.log('temp_game_details live 1',temp_game_details);
        setGameDetails(temp_game_details);
        let game_start = temp_game_details?.start_at;
        let game_start_local = moment.utc(game_start).toDate();
        let game_start_timestap = moment(game_start_local).format('X');
        let end_time_t = parseInt(game_start_timestap);
        if(temp_game_details?.status==1){
          end_time_t = end_time_t + parseInt(temp_game_details?.duration_in_minitue)*60;
        }
        end_time_t = end_time_t*1000;
        setEndTime(end_time_t);
        // console.log('temp_game_details live 2',game_details);
      }
    }
  });

  useChannel('game-list', 'end', (message) => {
    let list = JSON.parse(message?.data);
    console.log('end',JSON.parse(message?.data));
    if(list?.data?.length>0){
      let found = list?.data?.find((element) => element.id == game_id);
      console.log('end found',found);
      if(found){
        let temp_game_details = game_details;
        temp_game_details.status = found.status;
        temp_game_details.start_at = found.start_at;
        temp_game_details.end_at = found.end_at;
        temp_game_details.end_price = found.end_price;
        temp_game_details.winner_range_id = found.winner_range_id;
        if(game_joined?.is_joined){
          temp_game_details.is_joined = game_joined?.is_joined;
        }
        console.log('temp_game_details',temp_game_details);
        setGameDetails(temp_game_details);

        if(temp_game_details?.winner_range_id){          
          let found_win = range_list.find((element) => element.id == temp_game_details?.winner_range_id);
          console.log('found_win 1',found_win);
          if(found_win){
            let game_start = temp_game_details?.game_detail?.start_at;
            let game_start_local = moment.utc(game_start).toDate();
            let game_start_view = moment(game_start_local).format('LLL');
            found_win.game_start_view = game_start_view;
            let game_end = temp_game_details?.game_detail?.end_at;
            let game_end_local = moment.utc(game_end).toDate();
            let game_end_view = moment(game_end_local).format('LLL');
            found_win.game_end_view = game_end_view;
            found_win.user_target_view = null;
            let found_u = range_list.find((element) => element.id == game_joined?.is_joined);
            if(found_u){
              found_win.user_target_view = found_u?.string;
            }
            console.log('found_win 2',found_win);
            setWinnerData(found_win);
          }
        }
        console.log('game_joined',game_joined);
        if(game_joined?.is_joined){
          let found = range_list.find((element) => element.id == game_joined?.is_joined);
          if(found){
            setUserGameData(found);
          }
        }

        let game_start = temp_game_details?.start_at;
        let game_start_local = moment.utc(game_start).toDate();
        let game_start_timestap = moment(game_start_local).format('X');
        let end_time_t = parseInt(game_start_timestap);
        if(temp_game_details?.status==1){
          end_time_t = end_time_t + parseInt(temp_game_details?.duration_in_minitue)*60;
        }
        end_time_t = end_time_t*1000;
        setEndTime(end_time_t);
        // console.log('temp_game_details end 2',game_details);
      }
    }
  });

  useChannel('game-list', 'crypto-prize-pool', (message) => {
    // console.log('upcoming',message);
    console.log('crypto-prize-pool',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let dt = list[0];
      let temp_crypto = league_crypto_list.map(function(item){
        if(item.id == dt?.crypto_id){
          item.players = dt.players;
          item.prize_pool = dt.prize_pool;
          return item;
        }else{
          return item;
        }
      });
      setLeagueCryptoList(temp_crypto);
    }

  });

  useChannel('game-list', 'recent_end_range_list', (message) => {
    console.log('recent_end_range_list',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let found = list.filter((element) => element.game_id == game_id);
      if(found.length>0){
        let lts_rng = found;
        let temp_range_list=[];
        if(game_details?.league_id==2){
          let cm_price=game_details?.initial_price;
          if(game_details?.crypto_id==1){
            cm_price = parseFloat(btc_price);
          }
          if(game_details?.crypto_id==2){
            cm_price = parseFloat(eth_price);
          }
          if(game_details?.status==4){
            cm_price = parseFloat(game_details?.end_price);
          }

          lts_rng.map(function(item){
            let dt = item;
            let price_diff=parseFloat(parseFloat(item.range_id)-cm_price).toFixed(2);
            if(price_diff<0){
              price_diff=parseFloat(cm_price-parseFloat(item.range_id)).toFixed(2);
            }
            dt.price_diff=parseFloat(price_diff);
            temp_range_list.push(dt);
          });
          //if(game_details?.status==4){
            temp_range_list.sort(function(a,b){ return parseFloat(a.price_diff) - parseFloat(b.price_diff); });
          //}
          let temp_range_list_d = temp_range_list.map(function(item,i){
            let data = item;
            data.rank = i+1;
            return data;
          });
          setRangeList(temp_range_list_d);
        }       
      }
    }

  });

  let range_list_data = range_list;
  let my_range_list = [];
  let not_my_range_list = [];
  let won_prize = 0;

  if(game_details?.crypto_id==1){
    // btcprice_change
    if(range_list.length>0){
      let temp_p=null;
      let value_from=0;
      let last_day_price=0;
      if(game_details?.league_id==1){
        temp_p = range_list[2];
        value_from = temp_p.value_from;
      }
      if(game_details?.league_id==2){
        temp_p = range_list[0];
        value_from = temp_p.range_id;
      }
      if(league_crypto_list.length>0){
        last_day_price = league_crypto_list[0]?.last_day_price;
      }
     
      let price_diff = parseFloat(btc_price)-parseFloat(last_day_price);
      btcprice_change = parseFloat(price_diff/parseFloat(btc_price)*100).toFixed(2);
      if(game_details?.status==1){
        price_diff = parseFloat(btc_price)-parseFloat(value_from);
        btcprice_change = parseFloat(price_diff/parseFloat(btc_price)*100).toFixed(2);
      }      
    }
  }else if(game_details?.crypto_id==2){
    // ethprice_change
    if(range_list.length>0){
      let temp_p = range_list[2];
      let value_from = temp_p.value_from;
      let last_day_price=0;
      if(league_crypto_list.length>0){
        last_day_price = league_crypto_list[1]?.last_day_price;
      }
      let price_diff = parseFloat(eth_price)-parseFloat(last_day_price);
      ethprice_change = parseFloat(price_diff/parseFloat(eth_price)*100).toFixed(2);
      if(game_details?.status==1){
        price_diff = parseFloat(eth_price)-parseFloat(value_from);
        ethprice_change = parseFloat(price_diff/parseFloat(eth_price)*100).toFixed(2);
      }

    }
  }

  if(game_details?.league_id==2){
    let temp_range_list=[];
    if(game_details?.league_id==2){
      let cm_price=game_details?.initial_price;
      if(game_details?.crypto_id==1){
        cm_price = parseFloat(btc_price);
      }
      if(game_details?.crypto_id==2){
        cm_price = parseFloat(eth_price);
      }
      if(game_details?.status==4){
        cm_price = parseFloat(game_details?.end_price);
      }
      let cnt=1;
      range_list_data.map(function(item){
        let dt = item;
        let price_diff=parseFloat(parseFloat(item.range_id)-cm_price).toFixed(2);
        if(price_diff<0){
          price_diff=parseFloat(cm_price-parseFloat(item.range_id)).toFixed(2);
        }
        dt.price_diff=parseFloat(price_diff);
        if(item.username == user_Details?.username){
          dt.t_name='E'+cnt;
          cnt++;
        }else{
          dt.t_name='';
        }
        temp_range_list.push(dt);
      });
      if(game_details?.status==4){
        cm_price = parseFloat(game_details?.end_price);
      }

      if(game_details?.status==4){
        temp_range_list.sort(function(a,b){ return parseFloat(a.delta_point) - parseFloat(b.delta_point); });
        range_list_data = temp_range_list;
        my_range_list = range_list_data.filter(function (el) {
          return el.username == user_Details?.username;
        });
        won_prize = my_range_list.reduce((n, {prize}) => n + parseFloat(prize), 0);
        not_my_range_list = range_list_data.filter(function (el) {
          return el.username != user_Details?.username;
        });
        range_list_data =  my_range_list.concat(not_my_range_list);
      }else{
        temp_range_list.sort(function(a,b){ return parseFloat(a.price_diff) - parseFloat(b.price_diff); });
        let temp_range_list_d = temp_range_list.map(function(item,i){
          let data = item;
          data.rank = i+1;
          return data;
        })
        //range_list_data = temp_range_list_d;
        my_range_list = temp_range_list_d.filter(function (el) {
          return el.username == user_Details?.username;
        });
        not_my_range_list = temp_range_list_d.filter(function (el) {
          return el.username != user_Details?.username;
        });

        range_list_data =  my_range_list.concat(not_my_range_list);
      }

      
    }
  }

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  let current_time = Date.now();

  return <GameDetails
    is_fetching={is_fetching}
    range_list={range_list_data}
    my_range_list={my_range_list}
    game_data={game_details}
    ethprice={eth_price}
    btcprice={btc_price}
    ethlastPrice={eth_lastPrice}
    btclastPrice={btc_lastPrice}
    current_eth_price={current_eth_price}
    current_btc_price={current_btc_price}
    current_crypto={current_crypto}
    game_id={game_id}
    end_time={end_time}
    current_time={current_time}
    duration={end_time-current_time}
    btcprice_change={btcprice_change}
    ethprice_change={ethprice_change}
    getGameDetails={getGameDetails}
    setGameDetails={setGameDetails}
    updateGameStatus={updateGameStatus}
    game_joined={game_joined}
    my_games={my_games}
    winner_data={winner_data}
    user_game_data={user_game_data}
    userDetails={user_Details}
    won_prize={won_prize}
    total_winner_count={total_winner_count}
    navigate={navigate}
    {...props}
  />;
}

export default GameDetailsHOC;