import React,{useContext, useState, useEffect} from 'react';
import Frame from "../components/frame";
import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../context/AuthContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IMAGE_URL } from '../config';
import { useChannel } from 'ably/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast, { useToasterStore } from 'react-hot-toast';
import { userDetails } from '../api/auth';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { getPosters } from '../api/global';
import { CurrencySymbol } from '../utils/constants';
import { Autoplay } from 'swiper/modules';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  render() {    
    return (
      <Frame withHeader={true} withFooter={true} current_page={'home'}>
        {this.props.league_list?.length==0 ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="pt-55 pb-70">
          <div className="position-relative">
            <img className="hero_bg" src="assets/img/hero_bg.png" alt="" />
            <div className="link_benifits mb-10">
              <Link to="/user_info">Link a introducer code & earn benifits!  <i className="ms-1 icon-arr-right fs-10"></i> </Link>
            </div>

            <div className="tf-container">
              <div className="balance_box position-relative">
                <Link to="/user_info" className="d-flex align-items-center justify-content-between">
                  <div className="clapping_box">
                    <img className="clapping_img" src="assets/img/clapping.png" alt="" />
                    <h6 className="mb-4">Welcome back</h6>
                    <h4 className="mb-0">{this.props.userDetails?.username}</h4>
                  </div>
                  <div>
                    <i className="icon-arr-right"></i>
                  </div>
                </Link>
                <div className="shape_two"></div>
                <div className="shape_one"></div>
              </div>
              <div className="mt-18 text-center">
                <h6 className="mb-4 color7877">Total Balance</h6>
                <h1 className="fs-24 mb-1">
                  <Link to="/wallet">{parseFloat(this.props.total_balance).toFixed(2)} {CurrencySymbol}</Link>
                </h1>
                <p className="colorwhite">
                  {/* <Link to="/"><span className="colorprimary">Referal code :</span> {this.props.userDetails?.username} </Link>
                  <CopyToClipboard text={`${window.location.href}register/${this.props.userDetails?.username}`}
                    onCopy={() => toast.success('Copied Successfully!')}>
                    <button className="transparent_btn"><i className="icon icon-copy"></i></button>
                  </CopyToClipboard> */}
                  
                  {/* <button className="transparent_btn"><i className="icon icon-share"></i></button> */}
                </p>
              </div>
            </div>
            {this.props.posters.length>0?(
              <div className="tf-container mt-25">
                <div className="swiper tf-swiper swiper-wrapper-r" data-space-between="10" data-preview="1.1" data-tablet="2.2" data-desktop="1">
                  <Swiper
                    className='swiper-wrapper'
                    spaceBetween={10}
                    slidesPerView={1.1}
                    speed={1500}                  
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true}}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    {this.props.posters.map(function(item,index){
                      return (
                        <SwiperSlide key={`banner${index}`}>
                          <img className="announcement_slider" src={IMAGE_URL+item.poster} alt="" />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            ):(
              null
            )}            
          </div>

          <div className="pt-20 pb-20">
            <div className="tf-container">
              <div className="">
                <div className="heading_text mb-20">
                  <h4 className="mb-0">Game On Trade On</h4>
                </div>
                <div className="row g-3">
                  {this.props.league_list?.map(function(item,index){
                    return (
                      <div className="col-md-6 col-6" key={`league-${index}`}>
                        <Link to={`/discover/${item.id}`} className="leagues_img">
                          <img src={IMAGE_URL+item.image} alt="" />
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="pt-0 pb-20">
            <div className="tf-container">
              <div className="heading_text mb-12">
                <h4 className="mb-0">Referral Program</h4>
              </div>
              <div className="box_bg mt-10 position-relative">
                <div className="refer_img_b_p">
                  <img className="refer_img" src="assets/img/refer_img.webp" alt="" />
                  <img className="coin-r" src="assets/img/coin-r.png" alt="" />
                  <h4 className="mb-2 colorprimary">Refer and Earn Big!</h4>
                  <p>Unlock substantial rewards with our referal program.</p>
                  {/* <Link className="btn_style1" href="/referal_more">Know More</Link> */}
                  {parseFloat(this.props.userDetails?.totalDeposit)>0 ? (
                    <>
                      <button data-bs-toggle="dropdown" aria-expanded="false" className="btn_style1 border ms-1">Share
                        <i className="icon icon-share home_share_icon"></i>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" >                          
                          <WhatsappShareButton url={`${window.location.href}register/${this.props.userDetails?.username}`}>
                            <WhatsappIcon size={20} round />
                            <span> Share on WhatsApp</span>
                          </WhatsappShareButton>
                        </a>
                        <a className="dropdown-item" >                          
                          <TelegramShareButton url={`${window.location.href}register/${this.props.userDetails?.username}`}>
                            <TelegramIcon size={20} round />
                            <span> Share on Telegram</span>
                          </TelegramShareButton>
                        </a>
                        <a className="dropdown-item" >                          
                          <FacebookShareButton url={`${window.location.href}register/${this.props.userDetails?.username}`}>
                            <FacebookIcon size={20} round />
                            <span> Share on facebook</span>
                          </FacebookShareButton>
                        </a>
                        <a className="dropdown-item" >
                          <TwitterShareButton  url={`${window.location.href}register/${this.props.userDetails?.username}`}>
                            <TwitterIcon size={20} round />
                            <span> Share on twitter</span>
                          </TwitterShareButton>
                        </a>
                      </div>                      
                      
                      <CopyToClipboard text={`${window.location.href}register/${this.props.userDetails?.username}`}
                        onCopy={() => toast.success('Copied Successfully!')}>
                        <button className="btn_style1 border ms-1">Copy <i className="icon icon-copy home_share_icon"></i></button>
                      </CopyToClipboard>
                    </>                    
                  ):(
                    <>
                      <button onClick={()=>toast.error('Please add funds to refer!!')} className="btn_style1 border ms-1">Share
                        <i className="icon icon-share home_share_icon"></i>
                      </button>
                      <button onClick={()=>toast.error('Please add funds to copy referral link!!')} className="btn_style1 border ms-1">Copy
                        <i className="icon icon-copy home_share_icon"></i>
                      </button>
                    </>
                    
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pt-0 pb-10">
            <div className="tf-container">
              <div className="">
                <div className="heading_text mb-12">
                  <h4 className="mb-0">Highlights</h4>
                </div>
                <div className="swiper follow-swiper" data-space-between="10" data-preview="2" data-tablet="2" data-desktop="2">
                  <Swiper
                    className='swiper-wrapper'
                    spaceBetween={10}
                    slidesPerView={2}
                    speed={1500}                    
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                  >
                    <SwiperSlide><img className="announcement_slider" src="assets/img/follow-instagram.jpg" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="announcement_slider" src="assets/img/follow-telegram.jpg" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="announcement_slider" src="assets/img/follow-facebook.jpg" alt="" /></SwiperSlide>
                    <SwiperSlide><img className="announcement_slider" src="assets/img/follow-linkedin.jpg" alt="" /></SwiperSlide>                  
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="tf-container pt-20">
            <ul className="total_players_list">
              <li>
                <h4>99.3k</h4>
                <p>Total Players</p>
              </li>
              <li>
                <h4>10.5M USDT</h4>
                <p>Total earnings</p>
              </li>
              <li>
                <h4>3098.9k</h4>
                <p>Games Played</p>
              </li>
            </ul>
          </div> */}

        </div>
        
      </Frame >
    );
  }
}

function HomeHOC(props) {
  const { league_list,crypto_list, league_crypto_list,setLeagueCryptoList } = useContext(userInfo);
  
  const navigate = useNavigate();

  const [user_Details,setUserDetails] = useState(null);
  const [total_balance,setTotalBalance] = useState(0);
  const [is_fetching, setFetching] = useState(true);
  const [load_data, setLoadData] = useState(true);
  const [posters, setPosters] = useState([]);

  useEffect(() => {
    (async () => {
      if(is_fetching){
        setLoadData(false);
        let token = localStorage.getItem('token');
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          setUserDetails(user_response?.data);
          let total = user_response?.data?.wallet?.main||0+user_response?.data?.wallet?.bonus||0;
          setTotalBalance(total);
        }
        let poster = await getPosters();
        if(poster.status){
          setPosters(poster.data);
        }
        setFetching(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts 
    };

  }, [is_fetching,load_data,total_balance]);

  useChannel('game-list', 'crypto-prize-pool', (message) => {
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let dt = list[0];
      let temp_crypto = league_crypto_list.map(function(item){
        if(item.id == dt?.crypto_id){
          item.players = dt.players;
          item.prize_pool = dt.prize_pool;
          return item;
        }else{
          return item;
        }
      });
      setLeagueCryptoList(temp_crypto);
    }

  });

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return <Home
    league_list={league_list}
    crypto_list={crypto_list}
    userDetails={user_Details}
    total_balance={total_balance}
    posters={posters}
    navigate={navigate}
    {...props}
  />;
}

export default HomeHOC;