import React, { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { useChannel } from 'ably/react';
import { BaseCurrencySymbol, CurrencySymbol } from '../utils/constants';

class SingleGame extends React.Component {
  constructor(props) {
    super(props);
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    if(completed){
      return <span>Game Started</span>;
    }
    if(this.props.game_joined){
      return <span> {this.props.game_data?.status==0?'Starts in ':'Ends in '} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} | JOINED</span>;
    }else{
      return <span> {this.props.game_data?.status==0?'Starts in ':'Ends in '} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };

  render() {
    let range_data = [];
    if(this.props.game_joined && this.props.game_data?.league_id==2){
      range_data = this.props.game_joined?.range_string?.split(',');
    }
    return (
      <Link to={`/game_details/${this.props.game_data?.id}`} key={`game-up${this.props.game_data?.id}${this.props.game_data?.game_type_id}${this.props.game_data?.start_at}`}>
        <div className="leauges_game_box" id={`game_id_${this.props.game_data?.id}`}>
          <div className="starts_box">
            <Countdown key={`dl${this.props.game_data?.id}`} intervalDelay={0}
              precision={3} date={Date.now()+this.props.duration}
              renderer={this.renderer}
              onComplete={()=>{
                let game = document.getElementById(`game_id_${this.props.game_data?.id}`);
                if(game){
                  game.remove();
                }
              }}
            />
          </div>
          <h6 className='d-flex align-items-center justify-content-between'>{this.props.game_data?.crypto_id == 1?'BITCOIN':'ETHEREUM'}
          {this.props.game_joined?(
            this.props.game_data?.league_id==1?(
              <span className='game_selected'> You Selected {this.props.game_joined?.range_string}</span>
            ):(
              <div className="dropdown">
                <button type="button" className="dropdown-toggle btn_shadow_text" data-bs-toggle="dropdown">
                  Predictions {range_data[0]}
                </button>
                <div className="dropdown-menu p-2 btn_shadow_text_body">
                  {range_data.map(function(value){
                    return <span>{value}</span>
                  })}
                  {/* {this.props.game_joined?.range_string} */}
                </div>
              </div>
            )
            // <span className='game_selected'> You Selected {this.props.game_joined?.range_string}</span>
          ):(null)}
          </h6>
          {this.props.current_crypto?.code == 'BTC'?(
            <h4>{this.props.btcprice?this.props.btcprice:this.props.game_data?.initial_price} {BaseCurrencySymbol}
              {this.props.btcprice_change>=0?(
                <small className="ml_10 text-success"><i className="icon-select-up"></i> {this.props.btcprice_change}%</small>
              ):(
                <small className="ml_10 text-danger"><i className="icon-select-down"></i> {this.props.btcprice_change}%</small>
              )}
            </h4>
          ):(
            <h4>{this.props.ethprice?this.props.ethprice:this.props.game_data?.initial_price} {BaseCurrencySymbol}
              {this.props.ethprice_change>=0?(
                <small className="ml_10 text-success"><i className="icon-select-up"></i> {this.props.ethprice_change}%</small>
              ):(
                <small className="ml_10 text-danger"><i className="icon-select-down"></i> {this.props.ethprice_change}%</small>
              )}
            </h4>
          )}          
          <ul>
            <li>
              <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
              <p>Prize Pool</p>
            </li>
            <li>
              <h6>{this.props.game_data?.player}</h6>
              <p>Players</p>
            </li>
            <li>
              <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
              <p>duration</p>
            </li>
            <li>
              <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
              <p>entry fee</p>
            </li>
          </ul>
        </div>
      </Link>
    );
  }
}

function SingleGameHOC(props) {
  const { game_data } = props;
  const [game_details, setGameDetails] = useState(null);
  let game_start = game_data.start_at;
  let game_start_local = moment.utc(game_start).toDate();
  let game_start_timestap = moment(game_start_local).format('X');
  let end_time = parseInt(game_start_timestap);
  end_time = end_time*1000;
  let current_time = Date.now();
  let my_games = props.my_games;
  let game_joined = null;
  if(my_games.length>0){
    let found = my_games.find((element) => element.game_id == game_data?.id);
    if(found){
      game_joined = found;
    }
  }

  useEffect(() => {
    (async () => {
      setGameDetails(game_data);
    })();

    return () => {
      // this now gets called when the component unmounts
    };

  }, [game_details]);

  // useChannel('game-list', 'prize-pool', (message) => {
  //   // console.log('upcoming',message);
  //   console.log('prize-pool',JSON.parse(message?.data));
  //   let list = JSON.parse(message?.data);
  //   if(list.length>0){
  //     let found = list.find((element) => element.game_id == game_data?.id);
  //     if(found){
  //       let temp_game_details = game_details;
  //       temp_game_details.player = found.players;
  //       temp_game_details.prize_pool = found.prize_pool;
  //       setGameDetails(temp_game_details);
  //       console.log('temp_game_details 2',temp_game_details);
  //     }
  //   }

  // });

  // console.log('game_details rr',game_details);
  
  return <SingleGame
    game_data={game_details}
    end_time={end_time}
    current_time={current_time}
    duration={end_time-current_time}
    ethprice={props.ethprice}
    btcprice={props.btcprice}
    ethlastPrice={props.ethlastPrice}
    btclastPrice={props.btclastPrice}
    current_crypto={props.current_crypto}
    btcprice_change={props.btcprice_change}
    ethprice_change={props.ethprice_change}
    game_joined={game_joined}
    index={props.index}
    {...props}
  />;
}

export default SingleGameHOC;
