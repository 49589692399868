import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ Children, is_auth }) => {
  if(is_auth){
    return <Children />;
  }  
  return <Navigate to="/login" />  
};

export default ProtectedRoute;