import { API_URL } from "../config";

export const depositRequest = async (token,data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(data)
  };
  let path = '/user/deposit';

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const withdrawRequest = async (token,data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(data)
  };
  let path = '/user/withdraw';

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const uploadKyc = async (token,data) => {
  // let form_data = new FormData();
  // form_data.append('document_name', data.document_name);
  // form_data.append('document_number', data.document_number);
  // form_data.append('document_type', data.document_type);
  // form_data.append('document_front', data.document_front);
  // form_data.append('document_back', data.document_back);

  const options = {
    method: 'POST',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    // body:form_data
    body:JSON.stringify(data)
  };
  let path = '/user/kyc-doc-upload';

  return new Promise((resolve, reject) => {    
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}