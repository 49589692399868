import React, {useEffect,useState} from 'react';
import Frame from "../components/frame";
import { Link, useNavigate } from 'react-router-dom';
import toast, { useToasterStore } from 'react-hot-toast';
import { userDetails, getReferralTransactions } from '../api/auth';
import { CurrencySymbol } from '../utils/constants';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false      
    }
  }  

  render() {
    const d = new Date();
    let year = d.getFullYear();
    return (
      <Frame withHeader={false} withFooter={true}>
        {this.props.is_fetching || this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header header-style2 fixed-top bg-menuDark d-flex align-items-center">
          <Link to={'/referal'} className="left back-btn"><i className="icon-left-btn"></i></Link>
          <h5 className="mb-0">Analytics </h5>
          <div className="d-flex align-items-center gap-8 header_r_p">
            <a href="#logout" data-bs-toggle="modal" className="icon_circle"><img src="/assets/img/user-logout.png" alt="" /></a>
          </div>
        </div>

        <div className="pt-55 pb-70">
          <div className="tf-container">
            <div className="d-flex bg_box gradient_box_border2 mb-20 p-3 align-items-center justify-content-between">
              <p className='mb-0'>Current year</p>
              <span className='font-15 text-white'>{year}</span>
            </div>
          </div>
          <ul class="menu-tab-v3 mt-14 nav-justified" role="tablist">
            <li class="nav-link active" data-bs-toggle="tab" data-bs-target="#earnings_tab1" role="presentation">
              EARNINGS
            </li>
            {/* <li class="nav-link" role="presentation"  data-bs-toggle="tab" data-bs-target="#referal_tab">
              REFERRALS
            </li> */}
           
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade active show" id="earnings_tab1" role="tabpanel">
              <div className="tf-container">
                <div class="leauges_game_box">
                  <ul>
                    <li>
                      <h2 className='theme_color font-18'>{parseFloat(this.props.referral_transactions?.total_earning).toFixed(2)} {CurrencySymbol}</h2>
                      <p className='text-white'>Total Earnings</p>
                    </li>
                    <li>
                      <h2 className='theme_color font-18'>{parseFloat(this.props.referral_transactions?.todays_earning).toFixed(2)} {CurrencySymbol}</h2>
                      <p className='text-white'>Todays Earnings</p>
                    </li>
                  </ul>
                </div>
                <div className="mt-30 mb-20 text-center">
                  <div className="tf-container">
                    <div className='d-flex align-items-center justify-content-between mb-20'>
                      <div>Wallet</div>
                      <div>Source</div>
                      <div>Amount</div>
                      <div>Type</div>
                      {/* <div>Description</div> */}
                    </div>
                    {this.props.referral_transactions?.my_transactions?.length>0?(
                      this.props.referral_transactions?.my_transactions?.map(function(tx,index){
                        return (
                          <div key={`mtx_${index}`} className="bg_box p-3 mb-10 d-flex align-items-center justify-content-between">
                            <div className='referal_members_game'>                          
                              <h6 className='f text-capitalize'>{tx?.wallet}</h6>
                            </div>
                            <div className='text-capitalize theme_color font-12 fw-600'>{tx?.source}</div>
                            <div>{parseFloat(tx?.amount)>0 ? parseFloat(tx?.amount).toFixed(2)+` ${CurrencySymbol}` : `0 ${CurrencySymbol}`}</div>
                            <div className='text-capitalize theme_color font-12 fw-600'>{tx?.type}</div>
                            {/* <div className='text-capitalize font-12 fw-600'>{tx?.description}</div> */}
                          </div>
                        )
                      })
                    ):(
                      <>
                        <img className="no-img" src="/assets/img/bear-new.png" alt="" />
                        <h4 className='text-center'>Oops! No data to show</h4>
                      </>
                    )}
                  </div>
                </div>
                {/* <div class="tf-btn dark md mt-30">
                  <span className='font-12'>Your referal code:</span> <span className='text-white'>006578</span> <span><i class="icon icon-copy"></i></span>
                </div>
                <button class="tf-btn primary md mt-10">Share <i class="icon icon-share"></i></button> */}
              </div>
            </div>
            <div class="tab-pane fade" id="referal_tab" role="tabpanel">
              <div className="tf-container">
                <div class="leauges_game_box">
                  <ul>
                    <li>
                      <h2 className='theme_color font-18'>{parseFloat(this.props.referral_transactions?.referral_total_earning).toFixed(2)} {CurrencySymbol}</h2>
                      <p className='text-white'>Total Earnings</p>
                    </li>
                    <li>
                      <h2 className='theme_color font-18'>{parseFloat(this.props.referral_transactions?.referral_todays_earning).toFixed(2)} {CurrencySymbol}</h2>
                      <p className='text-white'>Todays Earnings</p>
                    </li>
                  </ul>
                </div>
                <div className="mt-30 mb-20 text-center">
                  <div className="tf-container">
                    <div className='d-flex align-items-center justify-content-between mb-20'>
                      <div>Username</div>
                      <div>Level</div>
                      <div>Amount</div>
                      <div>Bonus</div>
                    </div>
                    {this.props.referral_transactions?.referral_transactions?.length>0?(
                      this.props.referral_transactions?.referral_transactions?.map(function(tx,index){
                        return (
                          <div key={`tx_${index}`} className="bg_box p-3 mb-10 d-flex align-items-center justify-content-between">
                            <div className='referal_members_game'>                          
                              <h6 className='f'>{tx?.buyer}</h6>
                            </div>
                            <div className='theme_color font-12 fw-600'>{tx?.level}</div>
                            <div>{parseFloat(tx?.amount)>0 ? parseFloat(tx?.amount).toFixed(2)+` ${CurrencySymbol}` : `0 ${CurrencySymbol}`}</div>
                            <div>{parseFloat(tx?.bonus)>0 ? parseFloat(tx?.bonus).toFixed(2)+` ${CurrencySymbol}` : `0 ${CurrencySymbol}`}</div>
                          </div>
                        )
                      })
                    ):(
                      <>
                        <img className="no-img" src="/assets/img/bear-new.png" alt="" />
                        <h4 className='text-center'>Oops! No data to show</h4>
                      </>
                    )}
                  </div>
                </div>
                {/* <div class="tf-btn dark md mt-30">
                  <span className='font-12'>Your referal code:</span> <span className='text-white'>006578</span> <span><i class="icon icon-copy"></i></span>
                </div>
                <button class="tf-btn primary md mt-10">Share <i class="icon icon-share"></i></button> */}
              </div>
            </div>
           
          </div>
         
        </div>




       
      </Frame >
    );
  }
}

function UserInfoHOC(props) {
  const [user_Details,setUserDetails] = useState(null);
  const [referral_list,setReferralList] = useState([]);
  const [referral_transactions,setReferralTransactions] = useState([]);
  const [is_fetching, setFetching] = useState(true);
  const [load_data, setLoadData] = useState(true);

  useEffect(() => {
    (async () => {
      if(is_fetching){
        setLoadData(false);
        let token = localStorage.getItem('token');
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          setUserDetails(user_response?.data);
        }

        // let referral_list_ = await getReferrals(token);
        // if(referral_list_?.status){
        //   setReferralList(referral_list_?.data);
        // }

        let referral_trns = await getReferralTransactions(token);
        if(referral_trns?.status){
          setReferralTransactions(referral_trns?.data);
        }

        setFetching(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts 
    };

  }, [is_fetching,load_data]);

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const navigate = useNavigate();

  return <UserInfo
    is_fetching={is_fetching}
    userDetails={user_Details}
    referral_list={referral_list}
    referral_transactions={referral_transactions}
    setUserDetails={setUserDetails}
    setLoadData={setLoadData}
    navigate={navigate}
    {...props}
  />;
}

export default UserInfoHOC;