import { API_URL } from "../config";
// import axios from "axios";

export const userRegister = async (input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/register', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userLogin = async (input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/login', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userSendOtp = async (input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/sendotp', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userVerifyOtp = async (input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/verifyotp', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userDetails = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/user', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userWalletBalance = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {    
    fetch(API_URL+'/user/wallet-balance', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userTransactions = async (token,user_id) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {
    fetch(API_URL+`/user/transactions?user_id=${user_id}`, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userUpdateProfile = async (token,input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {
    fetch(API_URL+`/user/update-user`, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userUpdateAvatar = async (token,input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {
    fetch(API_URL+`/user/avatar-upload`, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getMyGames = async (token,date='') => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let url = API_URL+`/user/my-games`;
  if(date){
    url += '?date='+date;
  }
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getReferrals = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let url = API_URL+`/user/referral-list`;  
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getReferralTransactions = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let url = API_URL+`/user/referral-transactions`;  
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const userLogout = async (token) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {
    fetch(API_URL+'/user/logout', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const changePassword = async (token,input_data) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body:JSON.stringify(input_data)
  };
  return new Promise((resolve, reject) => {
    fetch(API_URL+`/user/change-password`, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}