import React from 'react';
import Header from "./header";
import Footer from "./footer";

class Frame extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { withHeader, withFooter, current_page, game_type } = this.props;
      return (
        <div>          
          {withHeader && <Header current_page={current_page} game_type={game_type}></Header> }           
          {this.props.children}
          {withFooter &&  <Footer></Footer>}
        </div>
      );
    }
  }

  export default Frame;