import React, { useContext, useEffect,useState } from 'react';
import Frame from "../components/frame";
import {Link, useNavigate, useParams} from "react-router-dom";
import { userInfo } from '../context/AuthContext';
import { IMAGE_URL } from '../config';
import { useChannel } from 'ably/react';
import { getCryptoList } from '../api/league';
import { CurrencySymbol } from '../utils/constants';


class Discover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }


  render() {
    let this2 = this;
    return (
      <Frame withHeader={true} withFooter={true} current_page={'discover'} game_type={this.props.game_type}>
        {this.props.is_fetching ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="pt-110 pb-70">        
          <div className="tab-content pt-0 pb-10">
            <div  className={`mt-10 tab-pane fade ${this.props.game_type==1?'active show':''}`} id={`leagues1`} role="tabpanel">
              <ul className="menu-tab-v3 mt-14 nav-justified" role="tablist">
                <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#game1_Crypto" role="presentation">
                  Crypto
                </li>
                {/* <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game1_Others">
                  Others
                </li> */}
              </ul>
              <div className="tab-content">
                <div className={`tab-pane fade ${this.props.game_type==1?'active show':''}`} id="game1_Crypto" role="tabpanel">
                  <div className="tf-container">
                    {this.props.crypto_data_target?.map(function(item,index){
                      return (
                        <div className="crypto_shadow_box" key={`crypto-${index}`}>
                          <Link to={`/leagues/1/${item.id}`}>
                            <div className="crypto_img_padding">
                              <span><img src={IMAGE_URL+item.image} alt="" /></span>
                              <h5>{item?.name}</h5>
                              <h6>{parseFloat(item?.prize_pool||0)} {CurrencySymbol} Prize pool</h6>
                            </div>
                            <h4>{parseInt(item?.players||0)} Players</h4>
                          </Link>
                          <div className="shadow green_shadow"></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="tab-pane fade" id="game1_Others" role="tabpanel">
                  <div className="tf-container">
                    <div className="not_found text-center">
                      <img src="/assets/img/not-found.png" alt="" />
                        <p className="mb-0 text-danger">The fun doesn't stop: More games arriving shortly</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className={`tab-pane fade ${this.props.game_type==2?'active show':''}`} id="leagues2" role="tabpanel">
              <ul className="menu-tab-v3 pt-10 mt-14 nav-justified" role="tablist">
                <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#game2_Crypto" role="presentation">
                  Crypto
                </li>
              </ul>
              <div className="tf-container">
                {this.props.crypto_data_precision?.map(function(item,index){
                  return (
                    <div className="crypto_shadow_box" key={`crypto-${index}`}>
                      <Link to={`/leagues/2/${item.id}`}>
                        <div className="crypto_img_padding">
                          <span><img src={IMAGE_URL+item.image} alt="" /></span>
                          <h5>{item?.name}</h5>
                          <h6>{parseFloat(item?.prize_pool||0)} {CurrencySymbol} Prize pool</h6>
                        </div>
                        <h4>{parseInt(item?.players||0)} Players</h4>
                      </Link>
                      <div className="shadow green_shadow"></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

      </Frame >
    );
  }
}

function DiscoverHOC(props) {
  let { game_type } = useParams();
  const [access_token,setAccessToken] = useState(true);
  const [is_auth,setIsAuth] = useState(true);
  const [is_fetching,setFetching] = useState(true);
  const [crypto_data_target,setCryptoDataTarget] = useState([]);
  const [crypto_data_precision,setCryptoDataPrecision] = useState([]);
  const { league_list,crypto_list, current_league, league_crypto_list,
    setLeagueCryptoList
   } = useContext(userInfo);
  const navigate = useNavigate();
  // if(!current_league){
  //   navigate('/');
  // }


  useEffect(() => {
    let token = localStorage.getItem('token');    
    if(!token){
      setIsAuth(false);
    }else{
      setAccessToken(token);
    }
    if(is_fetching){
      getCryptData(token);
    }

    return () => {
      // this now gets called when the component unmounts
    };

  }, [crypto_data_target,crypto_data_precision,is_auth,is_fetching]);

  const getCryptData = async (token)=> {
    let league_crypto_t = await getCryptoList(token,1);
    let league_crypto_p = await getCryptoList(token,2);
    if(league_crypto_t.status){
      setCryptoDataTarget(league_crypto_t?.data);
    }else{
      navigate('/');
    }
    if(league_crypto_p.status){
      setCryptoDataPrecision(league_crypto_p?.data);
    }
    setFetching(false);
  }

  useChannel('game-list', 'crypto-prize-pool', (message) => {
    // console.log('upcoming',message);
    // console.log('crypto-prize-pool',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let dt = list[0];
      let temp_crypto = crypto_list.map(function(item){
        if(item.id == dt?.crypto_id){
          item.players = dt.players;
          item.prize_pool = dt.prize_pool;
          return item;
        }else{
          return item;
        }
      });
      setLeagueCryptoList(temp_crypto);
    }

  });

  return <Discover
    game_type={game_type}
    league_list={league_list}
    crypto_list={crypto_list}
    current_league={current_league}
    league_crypto_list={league_crypto_list}
    crypto_data_target={crypto_data_target}
    crypto_data_precision={crypto_data_precision}
    is_fetching={is_fetching}
    {...props}
  />;
}

export default DiscoverHOC;