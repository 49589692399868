import React, {useContext, useEffect, useState} from 'react';
import Frame from "../components/frame";
import { Link, useNavigate } from 'react-router-dom';
import toast, { useToasterStore } from 'react-hot-toast';
import MainContract from "../contracts/MainContract.json";
import Web3 from "web3";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { ERC20ABI, Token_Contract, binanceSmartChain, metadata, projectId } from '../utils/constants';
import { userLogout, userSendOtp, userVerifyOtp, userWalletBalance } from '../api/auth';
import { depositRequest, withdrawRequest } from '../api/wallet';
import { getSettings } from '../api/global';
import { CurrencySymbol,BaseCurrencySymbol } from '../utils/constants';

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [binanceSmartChain],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-z-index': 9999
  }
})

const initialState = {
  loading: false,
  is_connected: false,
  web3: null,
  accounts: null,
  main_contract: null,
  contractAddress: null,
  YourAddress: '-----',
  YourAddressfull: null,
  deposit_amount:'',
  deposit_amount_jpy:'',
  withdraw_amount:'',
  withdraw_amount_jpy:'',
  input_otp:'',
  is_verify:false,
  is_send:false
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      web3: null,
      accounts: null,
      main_contract: null,
      contractAddress: null,
      YourAddress: '-----------',
      YourAddressfull: null,
      deposit_amount:'',
      deposit_amount_jpy:'',
      withdraw_amount:'',
      withdraw_amount_jpy:'',
      input_otp:'',
      is_verify:false,
      is_send:false
    }
  }

  handleLogout = async()=>{
    this.setState({loading:true});
    let token = localStorage.getItem('token');
    await userLogout(token);
    localStorage.setItem('token','');
    this.setState({loading:false});
    this.props.navigate('/login');
  }

  componentDidMount = async () => {
    try {
      this.setState({ contractAddress: MainContract.address });
    } catch (error) {
      this.setState({ networkMain: false, loading: false });
      console.error(error);
    }
  };


  componentDidUpdate = async () => {
    if (this.props?.address && this.props?.address != this.state.accounts) {
      this.setState({ accounts: this.props?.address });
      this.connectToWallet(false);
    }

    if (this.state.accounts && !this.props?.isConnected) {
      this.disconnectFromWallet();
    }
  }

  connectToWallet = async (show_msg=true) => {
    try {
      // Get network provider and web3 instance.
      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      // Use web3 to get the user's accounts.
      const accounts = this.props?.address;

      const networkId = this.props?.chainId;
      if (networkId !== 56) {
        toast.error("Error! Please connect to BSC mainnet");
        this.setState({ loading: false });
        return false;
      }

      if (this.state.accounts !== accounts) {
        // Get the contract instance.

        this.setState({ loading: true });
        const deployedNetwork = MainContract.networks;
        const instance = new web3.eth.Contract(
          MainContract.abi,
          deployedNetwork && MainContract.address, provider
        );

        let user_account = accounts;

        this.setState({ loading: false, is_connected: true });
        this.setState({ web3, accounts: user_account, main_contract: instance, loading: true }, this.fetchData);
        if(show_msg)
        toast.success("Wallet Connected");
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      toast.error(error.message);
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  };

  disconnectFromWallet = async () => {
    try {
      this.setState({ loading: true });
      if (this.state.web3) {
        toast.success("Wallet disconnected");
      }
      this.setState(initialState);
    } catch (error) {
      // Catch any errors for any of the above operations.
      toast.error(error.message);
      this.setState({ networkMain: false });
      console.error(error);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    let { accounts } = this.state;
    let YourAddress;
    if (accounts)
      YourAddress = accounts.slice(0, 6) + '.....' + accounts.slice(accounts.length - 5, accounts.length);
    let YourAddressfull = accounts;

    this.setState({
      YourAddress,
      YourAddressfull,
      loading: false,
      is_refresh: false
    });

  }

  deposit = async() => {
    try {   
      
      if(!this.state.accounts){
        toast.error('Please connect to wallet.');
        return false;
      }
      if(!this.state.deposit_amount){
        toast.error('Deposit amount is required!!');
        return false;
      }
      if(this.state.deposit_amount_jpy < parseFloat(this.props.min_deposit)){
        toast.error(`Min ${this.props.min_deposit} ${CurrencySymbol} is required!!`);
        return false;
      }

      let amount = parseFloat(this.state.deposit_amount);
      amount = amount*1e18;

      let provider = this.props?.walletProvider;
      let web3 = new Web3(modal);
      web3.setProvider(binanceSmartChain.rpcUrl);
      const deployedNetwork = MainContract.networks;
      let Contract = new web3.eth.Contract(
        ERC20ABI,
        deployedNetwork && Token_Contract, provider
      );

      let balance = await Contract.methods.balanceOf(this.state.accounts).call();
  
      console.log(balance);
      if(parseFloat(balance)<parseFloat(amount)){
        toast.error(`Insfucient Funds!!`);
        this.setState({loading:false});
        return false;
      }

      let weiValue = 0;
      this.setState({loading:true});
      let gasPrice = await this.state.web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 20;
      let gasLimit = 10000000;
      let estimateGas = 100000;

      let options = {
        from: this.state.accounts,
        gas: gasLimit,
        value:weiValue
      };
      // let data = await Contract.methods
      //   .approve(MainContract.address, amount.toString())
      //   .estimateGas(options);
      let data = 41000
      estimateGas = parseInt(data) + 10000;
      console.log("estimateGas",estimateGas)
      let approve_data = await Contract.methods
        .approve(MainContract.address, amount.toString())
        .send({
          from: this.state.accounts,
          value: 0,
          data: {
            "constant": false,
            "inputs": [
              {
                "internalType": "address",
                "name": "spender",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              }
            ],
            "name": "approve",
            "outputs": [
              {
                "internalType": "bool",
                "name": "",
                "type": "bool"
              }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
          },
          gas: estimateGas.toString(),
          gasPrice: gasPrice.toString(),
        });
      if (approve_data.status) {
        console.log(approve_data);
        weiValue = amount;
        let options = {
          from: this.state.accounts,
          gas: gasLimit,
          value: 0,
        };  
        // let data = await this.state.main_contract.methods.addCash(
        //   amount.toString()
        // ).estimateGas(options);
        // console.log("data",data)
        let data = 180000
        estimateGas = parseInt(data) + 10000;
        let claim_data = await this.state.main_contract.methods.addCash(
          amount.toString()
          ).send({
            from: this.state.accounts,
            value: 0,
            data: {
              "inputs": [
                {
                  "internalType": "uint256",
                  "name": "amount",
                  "type": "uint256"
                }
              ],
              "name": "addCash",
              "outputs": [],
              "stateMutability": "payable",
              "type": "function"
            },
            gas: estimateGas.toString(),
            gasPrice: gasPrice.toString(),
          });

        if (claim_data.status) {
          let data = {
            address:this.props.address,
            hash:claim_data?.transactionHash,
            usdt_amount:this.state.deposit_amount,
            jpy_amount:this.state.deposit_amount_jpy
          }
          let token = localStorage.getItem('token');
          let depo_data = await depositRequest(token,data);
          let msg = "Amount deposited Successfully!";
          if(depo_data.status){
            msg = depo_data.msg;
          }
          this.setState({ loading: false });
          toast.success(msg);
          this.fetchData();
        } else {
          this.setState({ loading: false });
          toast.error("Deposit failed!");
        }
      }   
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString());
    }
  }

  sendOTP = async() => {
    try {
      this.setState({loading:true});
      let user_details = localStorage.getItem('user_details');
      if(user_details){
        user_details = JSON.parse(user_details);
      }
      let input_data = {
        mobile_or_email:user_details?.email
      };
      let res_data = await userSendOtp(input_data);
      if(res_data.status){        
        this.setState({is_send:true});
        toast.success(res_data.msg);
      }
      this.setState({loading:false});
    } catch (error) {
      this.setState({loading:false});
    }
  }

  verifyOTP = async() => {
    try {

      if(!this.state.input_otp){
        toast.error('OTP is required!!');
        return false;
      }
      this.setState({loading:true});
      let user_details = localStorage.getItem('user_details');
      if(user_details){
        user_details = JSON.parse(user_details);
      }
      let input_data = {
        mobile_or_email:user_details?.email,
        otp:this.state.input_otp
      };
      let res_data = await userVerifyOtp(input_data);
      if(res_data.status){
        toast.success(res_data.msg);
        this.setState({is_verify:true});
      }else{
        if(res_data?.errors?.otp){
          toast.error(res_data?.errors?.otp);
        }else{
          toast.error(res_data?.msg);
        }
        
      }
      this.setState({loading:false});
    } catch (error) {
      this.setState({loading:false});
    }
  }

  withdraw = async() => {
    try {   
      if(!this.state.accounts){
        toast.error('Please connect to wallet.');
        return false;
      }
      if(!this.state.withdraw_amount){
        toast.error('Withdraw amount is required!!');
        return false;
      }

      if(this.state.withdraw_amount_jpy < parseFloat(this.props.min_withdraw)){
        toast.error(`Min withdarw value is ${this.props.min_withdraw} ${CurrencySymbol}!!`);
        return false;
      }

      this.setState({loading:true});

      let data = {
        address:this.props.address,
        amount:this.state.withdraw_amount_jpy,
        network:binanceSmartChain.chainId
      }
      let token = localStorage.getItem('token');
      let res_data = await withdrawRequest(token,data);
      let msg = "Amount withdraw Successfully!";
      if(res_data.status){
        this.setState({withdraw_amount:''});
        msg = res_data.msg;
        toast.success(msg);
      }else{
        msg = res_data.msg;
        toast.error(msg);
      }
      this.setState({ loading: false,is_send:false });
      this.fetchData();

    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString());
    }
  }

  render() {
    let this2 = this;
    return (
      <Frame withHeader={false} withFooter={true} current_page={'wallet'}>
        {this.props.is_fetching || this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header-style2 fixed-top bg-menuDark">
          <div className="d-flex justify-content-between align-items-center gap-14">
            <div className="box-account style-2">
              <Link to="/">
                <img src="assets/img/avt2.png" alt="img" className="avt" />
                <img src="assets/img/logo.png" alt="img" className="dashboard_logo" />                
              </Link>

            </div>
            <div className="d-flex align-items-center gap-8">
              {this.props.isConnected ? (
                <a
                  disabled={this.state.loading}
                  onClick={() => this.props.open()}
                  className="pointer"
                >
                  <i className="icon-sign-out-1"></i> {this.state.YourAddress}
                </a>
              ) : (
                <a
                  disabled={this.state.loading}
                  onClick={() => this.props.open()}
                  className="pointer"
                >
                  <i className="icon-sign-out-1"></i> Connect wallet
                </a>
              )}
              <Link to="/transaction_history" className="icon_circle"><i className="icon icon-clock"></i></Link>
              <a href="#logout" data-bs-toggle="modal" className="icon_circle"><img src="/assets/img/user-logout.png" alt="" /></a>
            </div>
          </div>
        </div>
        <div className="pt-55 pb-70">
          <div className="position-relative">
            <img className="hero_bg" src="assets/img/hero_bg.png" alt="" />
            <div className="pt-20 pb-10">
              <div className="tf-container">
                <ul className="game_balance_box">
                  <li>Main Wallet Balance
                    <span>{this.props.wallet_balance?.main||0} {CurrencySymbol}</span>
                  </li>
                  <li>Total Winnings
                    <span>{this.props.wallet_balance?.withdraw||0} {CurrencySymbol}</span>
                  </li>
                  <li>Deposit Bonus
                    <span>{this.props.wallet_balance?.bonus||0} {CurrencySymbol}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="pt-0 pb-10">
            <div className="tf-container">
              <Link to={'/transaction_history'} className="tf-btn primary w-500 md mt-30">Transaction History</Link>
            </div>
          </div>

          <div className="pt-20 pb-20">
            <div className="tf-container">
              <div className="tf-tab">
                <div className="tab-slide">
                  <ul className="nav nav-tabs wallet-tabs" role="tablist">
                    <li className="item-slide-effect"></li>
                    <li className="nav-item active" role="presentation">
                      <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#Deposit">Deposit</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Withdrawal">Withdrawal</button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content pt-10 pb-10">
                  <div className="tab-pane fade active show" id="Deposit" role="tabpanel">
                    <div className="mb-14">
                      <p className="mb-8 text-small">Currency</p>
                      <div className="select-wrapper" data-bs-toggle="modal" data-bs-target="#Deposit_currency">
                        <p className="tf-select dom-text mb-0">
                          <img className="tf-select_icon" src="assets/img/usdt.png" alt="" />
                          {BaseCurrencySymbol}
                          <span className="f_right_m">BSC (BEP20)</span>
                        </p>
                      </div>
                    </div>
                    <div className="mb-14">
                      <p className="mb-8 text-small">Amount in {BaseCurrencySymbol}</p>
                      <div className="position-relative box-input-field">
                        <input className="input_area clear-ip value_input" type="number" placeholder={`Enter ${BaseCurrencySymbol} amount to Deposit`}
                          value={this.state.deposit_amount}
                          onChange={(e)=>this.setState({deposit_amount:e.target.value},()=>{
                            let deposit_amount=this.state.deposit_amount;
                            if(!deposit_amount){
                              this.setState({deposit_amount:'',deposit_amount_jpy:''})
                              return;
                            }
                            if(deposit_amount.includes('.')){
                              let arr = deposit_amount.split('.');
                              if(!arr[1]){
                                return
                              }else{
                                this.setState({deposit_amount_jpy:parseFloat(deposit_amount*this.props.jpy_value).toFixed(2)})
                              }
                            }else{
                              this.setState({deposit_amount_jpy:parseFloat(deposit_amount*this.props.jpy_value).toFixed(2)})
                            }
                          })}
                        />
                        <i onClick={()=>this.setState({deposit_amount:'',deposit_amount_jpy:''})} className="icon-close"></i>
                      </div>
                      <p className="mb-8 mt-8 text-small">Amount in {CurrencySymbol}</p>
                      <div className="position-relative box-input-field">
                        <input className="input_area clear-ip value_input" type="number" placeholder={`Enter ${CurrencySymbol} amount to Deposit`}
                          value={this.state.deposit_amount_jpy}
                          onChange={(e)=>this.setState({deposit_amount_jpy:e.target.value},()=>{
                            let deposit_amount_jpy=this.state.deposit_amount_jpy;
                            if(!deposit_amount_jpy){
                              this.setState({deposit_amount:'',deposit_amount_jpy:''})
                              return;
                            }
                            if(deposit_amount_jpy.includes('.')){
                              let arr = deposit_amount_jpy.split('.');
                              if(!arr[1]){
                                return
                              }else{
                                this.setState({deposit_amount:parseFloat(deposit_amount_jpy/this.props.jpy_value).toFixed(2)})
                              }
                            }else{
                              this.setState({deposit_amount:parseFloat(deposit_amount_jpy/this.props.jpy_value).toFixed(2)})
                            }
                          })}
                        />
                        <i onClick={()=>this.setState({deposit_amount:'',deposit_amount_jpy:''})} className="icon-close"></i>
                      </div>
                      <div className="mt-4"><small>Min. deposit amount is {this.props.min_deposit} {CurrencySymbol}</small></div>
                    </div>

                    <ul className="grid-4 gap-8">
                      <li onClick={()=>this.setState({deposit_amount_jpy:750,deposit_amount:parseFloat(750/this.props.jpy_value).toFixed(2)})}><a className={`tag-money text-small ${this.state.deposit_amount_jpy==750?'active':''}`}>750 {CurrencySymbol}</a></li>
                      <li onClick={()=>this.setState({deposit_amount_jpy:5000,deposit_amount:parseFloat(5000/this.props.jpy_value).toFixed(2)})}><a className={`tag-money text-small ${this.state.deposit_amount_jpy==5000?'active':''}`}>5000 {CurrencySymbol}</a></li>
                      <li onClick={()=>this.setState({deposit_amount_jpy:10000,deposit_amount:parseFloat(10000/this.props.jpy_value).toFixed(2)})}><a className={`tag-money text-small ${this.state.deposit_amount_jpy==10000?'active':''}`}>10000 {CurrencySymbol}</a></li>
                      <li onClick={()=>this.setState({deposit_amount_jpy:50000,deposit_amount:parseFloat(50000/this.props.jpy_value).toFixed(2)})}><a className={`tag-money text-small ${this.state.deposit_amount_jpy==50000?'active':''}`}>50000 {CurrencySymbol}</a></li>
                    </ul>
                    <button disabled={this.state.loading} onClick={this.deposit} className="tf-btn primary w-500 md mt-30">Deposit</button>
                  </div>
                  <div className="tab-pane fade" id="Withdrawal" role="tabpanel">
                    <div className="mb-14">
                      <p className="mb-8 text-small">Currency</p>
                      <div className="select-wrapper" data-bs-toggle="modal" data-bs-target="#Withdrawal_currency">
                        <p className="tf-select dom-text mb-0">
                          <img className="tf-select_icon" src="assets/img/usdt.png" alt="" />
                          {BaseCurrencySymbol}
                          <span className="f_right_m">BSC</span>
                        </p>
                      </div>
                    </div>
                    <div className="mb-14">
                      <p className="mb-8 text-small">Balance</p>
                      <div className="select-wrapper" data-bs-toggle="modal" data-bs-target="#Winning_Balance">
                        <p className="tf-select dom-text mb-0">Main Wallet Balance</p>
                      </div>
                    </div>
                    <div className="mb-14">
                      {/* <p className="mb-8 text-small">Amount in {BaseCurrencySymbol}</p>
                      <div className="position-relative box-input-field">
                        <input className="input_area" type="number" placeholder={`Enter ${BaseCurrencySymbol} amount to withdraw`}
                          value={this.state.withdraw_amount}
                          onChange={(e)=>this.setState({withdraw_amount:e.target.value},()=>{
                            let withdraw_amount=this.state.withdraw_amount;
                            if(!withdraw_amount){
                              this.setState({withdraw_amount:'',withdraw_amount_jpy:''})
                              return;
                            }
                            if(withdraw_amount.includes('.')){
                              let arr = withdraw_amount.split('.');
                              if(!arr[1]){
                                return
                              }else{
                                this.setState({withdraw_amount_jpy:parseFloat(withdraw_amount*this.props.jpy_value).toFixed(2)})
                              }
                            }else{
                              this.setState({withdraw_amount_jpy:parseFloat(withdraw_amount*this.props.jpy_value).toFixed(2)})
                            }
                          })}
                        />
                        <span className="text-position">{BaseCurrencySymbol}</span>
                      </div> */}
                      <p className="mb-8 text-small">Amount in {CurrencySymbol}</p>
                      <div className="position-relative box-input-field">
                        <input className="input_area" type="number" placeholder={`Enter ${CurrencySymbol} amount to withdraw`}
                          value={this.state.withdraw_amount_jpy}
                          onChange={(e)=>this.setState({withdraw_amount_jpy:e.target.value},()=>{
                            let withdraw_amount_jpy=this.state.withdraw_amount_jpy;
                            if(!withdraw_amount_jpy){
                              this.setState({withdraw_amount:'',withdraw_amount_jpy:''})
                              return;
                            }
                            if(withdraw_amount_jpy.includes('.')){
                              let arr = withdraw_amount_jpy.split('.');
                              if(!arr[1]){
                                return
                              }else{
                                this.setState({withdraw_amount:parseFloat(withdraw_amount_jpy/this.props.jpy_value).toFixed(2)})
                              }
                            }else{
                              this.setState({withdraw_amount:parseFloat(withdraw_amount_jpy/this.props.jpy_value).toFixed(2)})
                            }
                          })}
                        />
                        <span className="text-position">{CurrencySymbol}</span>
                      </div>
                      <div className="mt-8">
                        <small>Min. Withdraw amount is {this.props.min_withdraw} {CurrencySymbol}</small>
                        {/* <span className="float-right w-600 text-primary">Check my limits</span> */}
                      </div>
                    </div>
                    {!this.state.is_verify?(
                      <div className="mb-14">
                        <p className="mb-8 text-small">OTP</p>
                        <div className="position-relative box-input-field">
                          <input className="input_area" type="number" placeholder="OTP"
                            value={this.state.input_otp}
                            onChange={(e)=>this.setState({input_otp:e.target.value})}
                          />
                          <div className="mt-8">
                            <span onClick={this.sendOTP} className="float-right w-600 text-primary pointer">Send Otp</span>
                          </div>
                          
                        </div>
                      </div>
                    ):(null)}
                    

                    {this.state.is_verify?(
                      <button disabled={this.state.loading} onClick={this.withdraw} className="tf-btn primary w-500 md mt-30">Withdraw</button>
                    ):(
                      <button disabled={this.state.loading} onClick={this.verifyOTP} className="tf-btn primary w-500 md mt-30">Verify</button>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade action-sheet" id="Deposit_currency">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Currency</h4>
              </div>
              <ul className="action_sheet_currency_select">
                <li className="selected">
                  <img src="assets/img/usdt.png" alt="" /> {BaseCurrencySymbol}
                  <span>BSC (BEP20)</span>
                </li>                
              </ul>
            </div>
          </div>
        </div>

        <div className="modal fade action-sheet" id="Withdrawal_currency">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Currency</h4>
              </div>
              <ul className="action_sheet_currency_select">
                <li className="selected">
                  <img src="assets/img/usdt.png" alt="" /> {BaseCurrencySymbol}
                  <span>BSC (BEP20)</span>
                </li>                
              </ul>
            </div>
          </div>
        </div>

        <div className="modal fade action-sheet" id="Winning_Balance">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Main Wallet Balance</h4>
              </div>
              <ul className="action_sheet_currency_select">
                <li className="selected">
                  Main Wallet Balance {this.props.wallet_balance?.main||0} {CurrencySymbol}
                </li>
                {/* <li>
                  Referral Earnings
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="modal fade" id="logout">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="header header-style2 d-flex align-items-center">                
                <h4 className="mb-0">Do you want to logout?</h4>
              </div>
              <hr className="m-0" />

              <div className="modal-body">
                <div className='header header-style2 d-flex align-items-center'>
                  <a data-bs-dismiss="modal" className='btn_style1 border'>No</a>
                  <a data-bs-dismiss="modal" onClick={this.handleLogout} className='btn_style1'>Yes</a>
                </div>                
              </div>
            </div>

          </div>
        </div>

      </Frame >
    );
  }
}

function HomeHOC(props) {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { signer, walletProvider } = useWeb3ModalSigner();
  const navigate = useNavigate();
  const [min_deposit, setMinDeposit] = useState(null);
  const [min_withdraw, setMinWithdraw] = useState(null);
  const [max_withdraw, setMaxWithdraw] = useState(null);
  const [settings, setSettings] = useState(null);
  const [wallet_balance, setWalletBalance] = useState(null);
  const [jpy_value, setJpyValue] = useState(null);
  const [is_fetching, setFetching] = useState(true);

  useEffect(() => {
    (async () => {
      if(is_fetching){
        let token = localStorage.getItem('token');
        let setting = await getSettings(token);
        if(setting.status){
          setSettings(setting.data);
          let min_depo = setting.data.find((element) => element.key == 'minimum_deposit_amount');
          if(min_depo){
            setMinDeposit(min_depo.value);
          }
          let min_wthd = setting.data.find((element) => element.key == 'withdraw_minimum_limit_in_24_hour');
          if(min_wthd){
            setMinWithdraw(min_wthd.value);
          }
          let max_wthd = setting.data.find((element) => element.key == 'withdraw_maximum_limit_in_24_hour');
          if(max_wthd){
            setMaxWithdraw(max_wthd.value);
          }
          let usd_to_jpy_value = setting.data.find((element) => element.key == 'usd_to_jpy_value');
          if(usd_to_jpy_value){
            setJpyValue(parseFloat(usd_to_jpy_value.value));
          }
          let userWBalance = await userWalletBalance(token);
          if(userWBalance.status){
            setWalletBalance(userWBalance?.data?.balance);
          }
        }
        setFetching(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };

  }, [settings,min_deposit,min_withdraw,is_fetching,wallet_balance]);

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return <Home
    open={open}
    address={address}
    chainId={chainId}
    isConnected={isConnected}
    signer={signer}
    walletProvider={walletProvider}
    navigate={navigate}
    settings={settings}
    min_deposit={min_deposit}
    min_withdraw={min_withdraw}
    max_withdraw={max_withdraw}
    wallet_balance={wallet_balance}
    jpy_value={jpy_value}
    {...props}
  />;
}

export default HomeHOC;