import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { BaseCurrencySymbol, CurrencySymbol } from '../utils/constants';

class SingleGameEnd extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let range_data = [];
    if(this.props.game_joined && this.props.game_data?.league_id==2){
      range_data = this.props.game_joined?.range_string?.split(',');
    }
    return (
      <Link to={`/game_details/${this.props.game_data?.id}`} key={`game-up${this.props.game_data?.id}${this.props.game_data?.game_type_id}${this.props.game_data?.start_at}`}>
        <div className="leauges_game_box">
          <div className="starts_box">
            <span> Ended {this.props.end_time}
              {/* {this.props.game_joined?'| JOINED':''} */}
            </span>
          </div>
          <h6 className='d-flex align-items-center justify-content-between'>{this.props.game_data?.crypto_id == 1?'BITCOIN':'ETHEREUM'}
            {this.props.game_joined?(
              this.props.game_data?.league_id==1?(
                <span className='game_selected'> You Selected {this.props.game_joined?.range_string}</span>
              ):(
                <div className="dropdown">
                  <button type="button" className="dropdown-toggle btn_shadow_text" data-bs-toggle="dropdown">
                    Predictions {range_data[0]}
                  </button>
                  <div className="dropdown-menu p-2 btn_shadow_text_body">
                    {range_data.map(function(value){
                      return <span>{value}</span>
                    })}
                    {/* {this.props.game_joined?.range_string} */}
                  </div>
                </div>
              )
              // <span className='game_selected'> You Selected {this.props.game_joined?.range_string}</span>
            ):(null)}
          </h6>
          <h4>{this.props.game_data?.end_price?this.props.game_data?.end_price:this.props.game_data?.initial_price} {BaseCurrencySymbol}
            {this.props.price_change>=0?(
              <small className="ml_10 text-success"><i className="icon-select-up"></i> {parseFloat(this.props.price_change).toFixed(2)}%</small>
            ):(
              <small className="ml_10 text-danger"><i className="icon-select-down"></i> {parseFloat(this.props.price_change).toFixed(2)}%</small>
            )}
          </h4>          
          <ul>
            <li>
              <h6>{this.props.game_data?.prize_pool} {CurrencySymbol}</h6>
              <p>Prize Pool</p>
            </li>
            <li>
              <h6>{this.props.game_data?.player}</h6>
              <p>Players</p>
            </li>
            <li>
              <h6>{this.props.game_data?.duration_in_minitue} Min</h6>
              <p>duration</p>
            </li>
            <li>
              <h6>{this.props.game_data?.entry_fee} {CurrencySymbol}</h6>
              <p>entry fee</p>
            </li>
          </ul>
          <p className='game_result'>
            {this.props.game_data?.status==2?'Result is in progress':''}
            {this.props.game_data?.status==3?'Game abandoned!!':''}
            {(this.props.game_data?.status==4 && !this.props.game_joined?.range_string)?'You did not join.':null}
            {(this.props.game_data?.status==4 && this.props.game_joined?.range_string)?'View Results...':null}
          </p>
        </div>
      </Link>
    );
  }
}

function SingleGameEndHOC(props) {
  const { game_data } = props;
  let game_end = game_data?.end_at;
  let game_end_local = moment.utc(game_end).toDate();
  let game_end_view = moment(game_end_local).format('LLL');
  
  let my_games = props.my_games;
  let game_joined = null;
  if(my_games.length>0){
    let found = my_games.find((element) => element.game_id == game_data?.id);
    if(found){
      game_joined = found;
    }
  }
  let price_change = 0;
  if(game_data?.end_price){
    let price_diff = parseFloat(game_data?.end_price)-parseFloat(game_data?.initial_price);
    price_change = parseFloat(price_diff/parseFloat(game_data?.end_price)*100).toFixed(2);
  }
 
  return <SingleGameEnd
    game_data={game_data}
    end_time={game_end_view}
    ethprice={props.ethprice}
    btcprice={props.btcprice}
    ethlastPrice={props.ethlastPrice}
    btclastPrice={props.btclastPrice}
    current_crypto={props.current_crypto}
    game_joined={game_joined}
    price_change={price_change}
    index={props.index}
    {...props}
  />;
}

export default SingleGameEndHOC;
