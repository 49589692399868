import React, {useEffect,useContext,useState} from 'react';
import Frame from "../../components/frame";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, useToasterStore } from 'react-hot-toast';
import { userLogin, userSendOtp, userDetails } from '../../api/auth';
import { validateEmail } from '../../utils/helper';
import { userInfo } from '../../context/AuthContext';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email:"",
      password:"",
      view_pass:false,
      login_type:'password',
      otp:''
    }
  }

  doLogin = async(e)=>{
    try {    
      e.preventDefault();    
      if(!this.state.email){
        toast.error('Email is required!!');
        return false;
      }
      if(!validateEmail(this.state.email)){
        toast.error('Invalid Email!!');
        return false;
      }    
      if(!this.state.password && this.state.login_type=='password'){
        toast.error('Password is required!!');
        return false;
      }
      if(!this.state.otp && this.state.login_type=='otp'){
        toast.error('OTP is required!!');
        return false;
      }
      this.setState({loading:true});
      let input_data = {
        email:this.state.email,
        password:this.state.password,
        login_type:this.state.login_type,
        otp:this.state.otp
      };
      let response = await userLogin(input_data);
      if(response.status){
        localStorage.setItem('token',response?.data?.plainTextToken);
        let token = response?.data?.plainTextToken;
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          this.props.setUserDetails(user_response?.data);
        }      

        this.setState({
          loading: false,
          email:"",
          password:"",
          view_pass:false,
          login_type:'password',
          otp:''
        });
        toast.success(response.msg);
        this.props.setAccessToken(token);
        this.props.setIsAuth(true);
        this.props.navigate('/');
      }else{
        if(response?.errors?.email){
          toast.error(response?.errors?.email?.toString());
        }else if(response?.errors?.password){
          toast.error(response?.errors?.password?.toString());
        }else if(response?.errors?.otp){
          toast.error(response?.errors?.otp?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading:false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading:false});
    }
  }

  doSendOtp = async()=>{
    try {
      if(!this.state.email){
        toast.error('Email is required!!');
        return false;
      }
      if(!validateEmail(this.state.email)){
        toast.error('Invalid Email!!');
        return false;
      }    
      this.setState({loading:true});
      let input_data = {
        mobile_or_email:this.state.email
      };
      let response = await userSendOtp(input_data);
      if(response.status){
        this.setState({loading: false});
        toast.success(response.msg);
      }else{
        if(response?.errors?.email){
          toast.error(response?.errors?.email?.toString());
        }else if(response?.errors?.mobile_or_email){
          toast.error(response?.errors?.mobile_or_email?.toString());
        }else if(response?.errors?.otp){
          toast.error(response?.errors?.otp?.toString());
        }else if(response?.msg){
          toast.error(response?.msg?.toString());
        }else{
          toast.error('Server error!!');
        }
        this.setState({loading: false});
      }
    } catch (error) {
      toast.error(error?.toString());
      this.setState({loading: false});
    }
  }

  render() {
    return (
      <Frame withHeader={false} withFooter={false}>
        {this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="pt-45 pb-20">
          <div className="tf-container mt-40">
            <img className="login_logo mb-25" src="/assets/img/logo.png" alt="" />
            <div className="position-relative">
              <div className="login_box">
                <h6 className="mb-20">Let's start off with entering your email ID.</h6>
                
                <div className="mb-18">
                  <p className="mb-8 text-small"> Email</p>
                  <input className="input_area" type="text" placeholder="Example@gmail"
                    onChange={(e)=>this.setState({email:e.target.value})}
                    value={this.state.email}
                  />
                </div>
                
                {this.state.login_type == 'otp' ? (
                  <div className="mb-18">
                    <p className="mb-8 text-small"> OTP 
                      {this.state.login_type == 'otp' ?(
                        <span onClick={()=>this.doSendOtp()} className='otp_login'>Send OTP</span>
                      ):(null)}
                    </p>
                    <input className="input_area" type="text" placeholder="OTP"
                      onChange={(e)=>this.setState({otp:e.target.value})}
                      value={this.state.otp}
                    />
                  </div>
                ):(
                  <div className="mb-18">
                    <p className="mb-8 text-small">Password</p>
                    <div className="box-auth-pass">
                      {this.state.view_pass ? (
                        <input type="text" required placeholder="Your password" className="input_area password-field"
                          onChange={(e)=>this.setState({password:e.target.value})}
                          value={this.state.password}
                        />
                      ):(
                        <input type="password" required placeholder="Your password" className="input_area password-field"
                          onChange={(e)=>this.setState({password:e.target.value})}
                          value={this.state.password}
                        />
                      )}
                      
                      <span className="show-pass" onClick={()=>this.setState({view_pass:!this.state.view_pass})}>
                        {!this.state.view_pass ? (
                          <i className="icon-view"></i>
                        ):(
                          <i className="icon-view-hide"></i>
                        )}
                      </span>
                    </div>
                  </div>
                )}


                {/* <Link to="/reset_password">Forgot Password?</Link> */}
                <span onClick={()=>this.setState({login_type:this.state.login_type=='otp'?'password':'otp'})} className='otp_login mb-18'>Login Using {this.state.login_type != 'otp' ?'OTP':'Password'}</span>
                <button disabled={this.state.loading} onClick={this.doLogin} className="tf-btn primary md mt-20">Login</button>
                <p className="mt-20 mb-0 text-center text-small">Already have a Account? &ensp;<Link to="/register">Sign up</Link></p>

              </div>
              <div className="shape_two"></div>
              <div className="shape_one"></div>
            </div>

          </div>
        </div>

      </Frame >
    );
  }
}

function LoginHOC(props) {
  const { setUserDetails, setAccessToken, setIsAuth } = useContext(userInfo);
  // console.log(userDetails)
  const navigate = useNavigate();
  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const [is_fetching, setFetching] = useState(true);
  // useEffect(() => {
  //   (async () => {
  //     if(is_fetching){
  //       let token = localStorage.getItem('token');
  //       if(token){
  //         navigate('/');
  //       }
  //     }
  //     setFetching(false);
  //   })();

  // }, [is_fetching]);
  
  return <Login
    navigate={navigate}
    setUserDetails={setUserDetails}
    setAccessToken={setAccessToken}
    setIsAuth={setIsAuth}
    is_fetching={is_fetching}
    {...props}
  />;
}

export default LoginHOC;