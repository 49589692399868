import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { userLogout } from '../api/auth';
import { userInfo } from '../context/AuthContext';
import { IMAGE_URL } from '../config';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    }
  }

  handleLogout = async()=>{
    this.setState({loading:true});
    let token = localStorage.getItem('token');
    await userLogout(token);
    localStorage.setItem('token','');
    this.setState({loading:false});
    this.props.navigate('/login');
  }

  render() {
    let current_page = this.props.current_page;
    let game_type = this.props.game_type;
    return (
      <>
        {this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header-style2 fixed-top bg-menuDark">
          <div className="d-flex justify-content-between align-items-center gap-14">
            <div className="box-account style-2">
              <Link to="/user_info">
                {this.props.userDetails?.avatar?(
                  <img className='avatar_view_sm' src={IMAGE_URL+this.props.userDetails?.avatar} alt="img" />
                ):(
                  <img className='avatar_view_sm' src="/assets/img/avt2.png" alt="img" />
                )}
              </Link>
              <Link to="/">
                <img src="/assets/img/logo.png" alt="img" className="dashboard_logo" />
              </Link>
            </div>
            <div className="d-flex align-items-center gap-8">
              {/* {current_page == 'home' ? (
                <a href="#filter" data-bs-toggle="modal">
                  <img className="star_icon" src="/assets/img/star.png" aly="" />
                </a>
              ):(null)} */}

              {current_page == 'discover' || current_page == 'my-leagues' ? (
                <Link to="/rules_instructions" className="icon_circle"><i className="icon icon-question"></i></Link>
              ):(null)}

              {current_page == 'wallet' ? (
                <Link to="/transaction_history" className="icon_circle"><i className="icon icon-clock"></i></Link>
              ):(null)}
              
              <a href="#logout" data-bs-toggle="modal" className="icon_circle"><img src="/assets/img/user-logout.png" alt="" /></a>
            </div>            
          </div>
          {current_page == 'discover' || current_page == 'my_league' ? (
            <div className="pt-2 pb-0">
              <div className="top_tab">
                <ul className="nav nav-tabs game_img_tab" role="tablist">
                  {this.props.league_list?.map(function(item,index){
                    return (
                      <li key={`tab_lists_${index}`} className={`nav-link ${game_type==index+1?'active':''}`} data-bs-toggle="tab" data-bs-target={`#leagues${item.id}`} role="presentation">
                        <Link to={`/${current_page}/${item.id}`}>
                          <img src={IMAGE_URL+item.image} alt="" />
                        </Link>                        
                      </li>
                    )
                  })}
                </ul>
              </div>            
            </div>
          ):(null)}
        </div>

        <div className="modal fade action-sheet" id="filter">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="header header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Notification</h4>
              </div>
              <hr className="m-0" />

              <div className="modal-body">
                <ul className="modal_link_menu mb-0">
                  <li><a href="#"> Announcements</a></li>
                  <li><a href="#">Skill Score Ratings</a></li>
                  <li><a href="#">Earnings Leaderboard</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>

        <div className="modal fade" id="logout">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="header header-style2 d-flex align-items-center">                
                <h4 className="mb-0">Do you want to logout?</h4>
              </div>
              <hr className="m-0" />

              <div className="modal-body">
                <div className='header header-style2 d-flex align-items-center'>
                  <a data-bs-dismiss="modal" className='btn_style1 border'>No</a>
                  <a data-bs-dismiss="modal" onClick={this.handleLogout} className='btn_style1'>Yes</a>
                </div>                
              </div>
            </div>

          </div>
        </div>
      </>
      
    );
  }
}

function HeaderHOC(props) {
  const { league_list, userDetails } = useContext(userInfo);
  const navigate = useNavigate();
  const { current_page, game_type } = props;
  return <Header
    current_page={current_page}
    game_type={game_type}
    league_list={league_list}
    userDetails={userDetails}
    navigate={navigate}
    {...props}
  />;
}

export default HeaderHOC;