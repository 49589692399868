import { API_URL } from "../config";

export const getCurrentPrice = async (currency) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return new Promise((resolve, reject) => {    
    fetch(`https://api.coinbase.com/v2/exchange-rates?currency=${currency}`, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getSettings = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let path = '/user/settings';
  
  return new Promise((resolve, reject) => {
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getCountryList = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
  let path = '/user/countries';
  
  return new Promise((resolve, reject) => {
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getdemo = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  let path = '/user/countries';
  
  return new Promise((resolve, reject) => {
    fetch('https://admin.royalmint.tech/backend/api/package-list', options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}

export const getPosters = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  let path = '/user/poster-list';
  
  return new Promise((resolve, reject) => {
    fetch(API_URL+path, options)
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch(err => resolve(err));
  });
}