import React from 'react';
import Frame from "../components/frame";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }


  render() {

    return (
      <Frame withHeader={true} withFooter={false} current_page={'404'}>
        <div className="pt-45 pb-20">
          <div className="tf-container mt-40">
            <h4>Page not found!!</h4>
          </div>
        </div>

      </Frame >
    );
  }
}

function HomeHOC(props) {
  return <Home
    {...props}
  />;
}

export default HomeHOC;