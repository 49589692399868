import React from 'react';
import Frame from "../components/frame";
import { Link } from 'react-router-dom';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  render() {
    return (
      <Frame withHeader={false} withFooter={true}>
        <div class="header header-style2 fixed-top bg-menuDark d-flex align-items-center">
          <Link to="/discover/1" class="left back-btn"><i class="icon-left-btn"></i></Link>
          <h5 class="mb-0">Rules Instructions</h5>
        </div>

        <div class="pt-55 pb-70">
          <div class="pt-0 pb-20">
            <ul class="menu-tab-v3 mt-2 nav-justified" role="tablist">
              <li class="nav-link active" data-bs-toggle="tab" data-bs-target="#How-to" role="presentation">
                How to
              </li>
              <li class="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#Rules">
                Rules
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade active show" id="How-to" role="tabpanel">
                <div class="tf-container">
                  <div class="bg_box p-3">
                    How to
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="Rules" role="tabpanel">
                <div class="tf-container">
                  <div class="bg_box p-3">
                    Rules
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </Frame >
    );
  }
}

function HomeHOC(props) {
  return <Home
    {...props}
  />;
}

export default HomeHOC;