import React, { useEffect, useState, useContext } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Frame from "../components/frame";
import { useNavigate, useParams } from 'react-router-dom';
import { getGameList } from '../api/league';
import SingleGameHOC from '../components/single_game';
import SingleGameLiveHOC from '../components/single_game_live';
import SingleGameEndHOC from '../components/single_game_end';
import { userInfo } from '../context/AuthContext';
import { useChannel, useChannelStateListener, useConnectionStateListener } from 'ably/react';

class Leagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    return <span> Starts in {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  };

  render() {
    let this2 = this;
    return (
      <Frame withHeader={true} withFooter={true} current_page={'leagues'}>
        {this.props.is_fetching ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}

        <div className="pt-65 pb-70">
          <div className="">
            <ul className="menu-tab-v3 mt-0 nav-justified" role="tablist">
              <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#game1_Upcoming_game" role="presentation">
                Upcoming
              </li>
              <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game1_Live_game">
                Live
              </li>
              <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game1_Completed_game">
                Completed
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="game1_Upcoming_game" role="tabpanel">
                <div className="tf-container">
                  {/* <div className="row g-2 mb-16">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Time:
                        <span className="active">All</span>
                        <span>≤15m</span>
                        <span>{'>'}15m</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Fees:
                        <span className="active">All</span>
                        <span>≤$10</span>
                        <span>{'>'}$10</span>
                      </div>
                    </div>
                  </div> */}

                  {this.props.upcoming_game_list?.intra_day?.length>0?(                    
                    this.props.upcoming_game_list?.intra_day?.map(function(item,index){
                      if(index==0){
                        return(
                          <>
                            <div className="heading_text mb-12">
                              <h4 className="mb-0">Intraday Games</h4>
                            </div>
                            <SingleGameHOC 
                              key={`sgid${item.id}${index}`}
                              game_data={item} index={index}
                              ethprice={this2.props.ethprice}
                              btcprice={this2.props.btcprice}
                              ethlastPrice={this2.props.ethlastPrice}
                              btclastPrice={this2.props.btclastPrice}
                              current_crypto={this2.props.current_crypto}
                              btcprice_change={this2.props.btcprice_change}
                              ethprice_change={this2.props.ethprice_change}
                              my_games={this2.props.my_games}
                            />
                          </>
                        )
                      }else{
                        return(
                          <SingleGameHOC 
                            key={`sgid${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      }
                    })
                  ):(null)}

                  {this.props.upcoming_game_list?.hour_long?.length>0?(                    
                    this.props.upcoming_game_list?.hour_long?.map(function(item,index){
                      if(index==0){
                        return(
                          <>
                            <div className="heading_text mb-12">
                              <h4 className="mb-0">Hour-Long Games</h4>
                            </div>
                            <SingleGameHOC 
                              key={`sghl${item.id}${index}`}
                              game_data={item} index={index}
                              ethprice={this2.props.ethprice}
                              btcprice={this2.props.btcprice}
                              ethlastPrice={this2.props.ethlastPrice}
                              btclastPrice={this2.props.btclastPrice}
                              current_crypto={this2.props.current_crypto}
                              btcprice_change={this2.props.btcprice_change}
                              ethprice_change={this2.props.ethprice_change}
                              my_games={this2.props.my_games}
                            />
                          </>
                        )
                      }else{
                        return(
                          <SingleGameHOC 
                            key={`sghl${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      }
                    })
                  ):(null)}

                  {this.props.upcoming_game_list?.day_long?.length>0?(                    
                    this.props.upcoming_game_list?.day_long?.map(function(item,index){
                      if(index==0){
                        return(
                          <>
                            <div className="heading_text mb-12">
                              <h4 className="mb-0">Day-Long Games</h4>
                            </div>
                            <SingleGameHOC 
                              key={`sgdl${item.id}${index}`}
                              game_data={item} index={index}
                              ethprice={this2.props.ethprice}
                              btcprice={this2.props.btcprice}
                              ethlastPrice={this2.props.ethlastPrice}
                              btclastPrice={this2.props.btclastPrice}
                              current_crypto={this2.props.current_crypto}
                              btcprice_change={this2.props.btcprice_change}
                              ethprice_change={this2.props.ethprice_change}
                              my_games={this2.props.my_games}
                            />
                          </>
                        )
                      }else{
                        return(
                          <SingleGameHOC 
                            key={`sgdl${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      }
                    })
                  ):(null)}

                  {this.props.upcoming_game_list?.free_game?.length>0?(                    
                    this.props.upcoming_game_list?.free_game?.map(function(item,index){
                      if(index==0){
                        return(
                          <>
                            <div className="heading_text mb-12">
                              <h4 className="mb-0">Free Games</h4>
                            </div>
                            <SingleGameHOC 
                              key={`sgfg${item.id}${index}`}
                              game_data={item} index={index}
                              ethprice={this2.props.ethprice}
                              btcprice={this2.props.btcprice}
                              ethlastPrice={this2.props.ethlastPrice}
                              btclastPrice={this2.props.btclastPrice}
                              current_crypto={this2.props.current_crypto}
                              btcprice_change={this2.props.btcprice_change}
                              ethprice_change={this2.props.ethprice_change}
                              my_games={this2.props.my_games}
                            />
                          </>
                        )
                      }else{
                        return(
                          <SingleGameHOC 
                            key={`sgfg${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      }
                    })
                  ):(null)}

                  {this.props.game_list?.upcoming?.data?.length==0?(
                    <>
                      <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                      <p className='text-center'>No Data Available!!</p>
                    </>
                  ):(null)}
                  
                </div>
              </div>
              <div className="tab-pane fade" id="game1_Live_game" role="tabpanel">
                <div className="tf-container">
                  {/* <div className="row g-2 mb-16">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Time:
                        <span className="active">All</span>
                        <span>≤15m</span>
                        <span>{'>'}15m</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Fees:
                        <span className="active">All</span>
                        <span>≤$10</span>
                        <span>{'>'}$10</span>
                      </div>
                    </div>
                  </div> */}
                  {this.props.live_game_list?.length>0?(                    
                    this.props.live_game_list?.map(function(item,index){
                      return(
                        <SingleGameLiveHOC 
                          key={`sglg${item.id}${index}`}
                          game_data={item} index={index}
                          ethprice={this2.props.ethprice}
                          btcprice={this2.props.btcprice}
                          ethlastPrice={this2.props.ethlastPrice}
                          btclastPrice={this2.props.btclastPrice}
                          current_crypto={this2.props.current_crypto}
                          btcprice_change={this2.props.btcprice_change}
                          ethprice_change={this2.props.ethprice_change}
                          my_games={this2.props.my_games}
                        />
                      )
                    })
                  ):(null)}
                  {this.props.game_list?.live?.data?.length==0?(
                    <>
                    <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                      <p className='text-center'>No Data Available!!</p>
                    </>
                  ):(null)}
                </div>
              </div>
              <div className="tab-pane fade" id="game1_Completed_game" role="tabpanel">
                <div className="tf-container">
                  {/* <div className="row g-2 mb-16">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Time:
                        <span className="active">All</span>
                        <span>≤15m</span>
                        <span>{'>'}15m</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="time_bg">
                        Fees:
                        <span className="active">All</span>
                        <span>≤$10</span>
                        <span>{'>'}$10</span>
                      </div>
                    </div>
                  </div> */}
                  {this.props.end_game_list?.length>0?(                    
                    this.props.end_game_list?.map(function(item,index){
                      return(
                        <SingleGameEndHOC 
                          key={`sgeg${item.id}${index}`}
                          game_data={item} index={index}
                          ethprice={this2.props.ethprice}
                          btcprice={this2.props.btcprice}
                          ethlastPrice={this2.props.ethlastPrice}
                          btclastPrice={this2.props.btclastPrice}
                          current_crypto={this2.props.current_crypto}
                          btcprice_change={this2.props.btcprice_change}
                          ethprice_change={this2.props.ethprice_change}
                          my_games={this2.props.my_games}
                        />
                      )
                    })
                  ):(null)}
                  {this.props.game_list?.end?.data?.length==0?(
                    <>
                    <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                    <p className='text-center'>No Data Available!!</p>
                    </>
                  ):(null)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </Frame >
    );
  }
}

function LeaguesHOC(props) {
  let { league_id, crypto_id } = useParams();
  const [game_list,setGameList] = useState([]);  
  const [up_game_list_temp,setUpGameListTemp] = useState([]);
  const [live_game_list,setLiveGameList] = useState([]);
  const [end_game_list,setEndGameList] = useState([]);
  const [is_fetching,setFetching] = useState(true);
  const [load_data,setLoadData] = useState(true);
  const navigate = useNavigate();
  if(!league_id){
    navigate('/')
  }
  if(!crypto_id){
    navigate('/')
  }

  const { eth_price, btc_price, eth_lastPrice, btc_lastPrice, current_crypto,
     current_btc_price, current_eth_price,btcprice_change,ethprice_change,
     upcoming_game_list,setUpcomingGameList,crypto_list,setCurrentCrypto,
     league_crypto_list,setLeagueCryptoList,
     my_games} = useContext(userInfo);

  useEffect(() => {
    setLoadData(false);
    if(is_fetching){
      getGames();
    }    
  }, [current_crypto,load_data]);

  const getGames = async()=>{
    if(crypto_list?.length){
      let cc = crypto_list.find((element) => element.id == crypto_id);
      if(cc){
        if(crypto_id!=current_crypto?.id){
          setFetching(true);
          var upcoming_game = {
            intra_day:[],
            hour_long:[],
            day_long:[],
            free_game:[]
          };
          setGameList([]);
          setUpGameListTemp([]);
          setUpcomingGameList(upcoming_game);
          setLiveGameList([]);
          setEndGameList([]);
        }
        setCurrentCrypto(cc);
      }
    }
    let token = localStorage.getItem('token');
    let game_list = await getGameList(token,league_id,crypto_id);
    setGameList(game_list?.data);
    setUpGameListTemp(game_list?.data?.upcoming?.data);
    UpcomingDestruct(game_list?.data?.upcoming?.data);
    setFetching(false);
    // setLoadData(false);
    let game_list_live = game_list?.data?.live?.data;
    if(game_list_live?.length>0){
      setLiveGameList(game_list_live);
    }

    let end_game_list = game_list?.data?.end?.data;
    if(end_game_list?.length>0){
      setEndGameList(end_game_list);
    }
  }

  const UpcomingDestruct = (data)=>{
    var intra_day=[];
    var hour_long=[];
    var day_long=[];
    var free_game=[];
    
    let game_list_upcoming = data;
    if(game_list_upcoming?.length>0){
      game_list_upcoming?.map(function(item,index){
        if(item.duration_in_minitue<60 && parseInt(item.entry_fee)>0){
          intra_day.push(item);
        }
        if(item.duration_in_minitue<60*24 && item.duration_in_minitue>=60){
          hour_long.push(item);
        }
        if(item.duration_in_minitue>=60*24){
          day_long.push(item);
        }
        if(parseInt(item.entry_fee)==0){
          free_game.push(item);
        }
      });
      var upcoming_game = {
        intra_day:intra_day,
        hour_long:hour_long,
        day_long:day_long,
        free_game:free_game
      };
      setUpcomingGameList(upcoming_game);        
    }
  }


  // console.log('crypto_id',crypto_id);
  // console.log('current_crypto',current_crypto?.id);

  // const [messages, setMessages] = useState([]);

  useConnectionStateListener('connected', () => {
    console.log('Connected to Ably!');
  });

  // Create a channel called 'get-started' and subscribe to all messages with the name 'first' using the useChannel hook
  useChannel('game-list', 'upcoming', (message) => {
    // console.log('upcoming',message);
    console.log('upcoming',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length){
      let filter_list = list.filter(function (el) {        
        return el.crypto_id == crypto_id && el.league_id==league_id;
      });
      setUpGameListTemp(filter_list);
      UpcomingDestruct(filter_list);
      setLoadData(true); 
    }
    
    // setMessages(previousMessages => [...previousMessages, message]);
  });

  useChannel('game-list', 'live', (message) => {
    // console.log('live',message);
    console.log('live',JSON.parse(message?.data));
    // setLiveGameList(JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length){
      let filter_list = list.filter(function (el) {
        return el.crypto_id == crypto_id && el.league_id==league_id;
      });
      setLiveGameList(filter_list);
      setLoadData(!load_data);
    }
    
    // setMessages(previousMessages => [...previousMessages, message]);
  });

  useChannel('game-list', 'end', (message) => {
    // console.log('end',message);
    console.log('end',JSON.parse(message?.data));
    // setEndGameList(JSON.parse(message?.data?.data));
    let list = JSON.parse(message?.data);
    if(list?.data?.length){
      let filter_list = list?.data?.filter(function (el) {
        return el.crypto_id == crypto_id && el.league_id==league_id;
      });
      setEndGameList(filter_list);
    }
    
    // setMessages(previousMessages => [...previousMessages, message]);
  });

  useChannel('game-list', 'prize-pool', (message) => {
    // console.log('upcoming',message);
    // console.log('prize-pool',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let list_data = list[0];
      let filter_list = up_game_list_temp.map(function(game){
        if(list_data?.game_id==game.id){
          game.player = list_data?.players;
          game.prize_pool = list_data?.prize_pool;
          return game;
        }else{
          return game;
        }
      });
      setUpGameListTemp(filter_list);
      UpcomingDestruct(filter_list);      
    }

  });

  useChannel('game-list', 'crypto-prize-pool', (message) => {
    // console.log('upcoming',message);
    // console.log('crypto-prize-pool',JSON.parse(message?.data));
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let dt = list[0];
      let temp_crypto = league_crypto_list.map(function(item){
        if(item.id == dt?.crypto_id){
          item.players = dt.players;
          item.prize_pool = dt.prize_pool;
          return item;
        }else{
          return item;
        }
      });
      setLeagueCryptoList(temp_crypto);
    }

  });

  // console.log('messages',messages);

  return <Leagues    
    league_id={league_id}
    crypto_id={crypto_id}
    game_list={game_list}
    upcoming_game_list={upcoming_game_list}
    live_game_list={live_game_list}
    end_game_list={end_game_list}
    is_fetching={is_fetching}
    ethprice={eth_price}
    btcprice={btc_price}
    ethlastPrice={eth_lastPrice}
    btclastPrice={btc_lastPrice}
    current_eth_price={current_eth_price}
    current_btc_price={current_btc_price}
    current_crypto={current_crypto}
    btcprice_change={btcprice_change}
    ethprice_change={ethprice_change}
    my_games={my_games}
    {...props}
  />;
}

export default LeaguesHOC;