import React, {useEffect,useState} from 'react';
import Frame from "../components/frame";
import { Link, useNavigate } from 'react-router-dom';
import toast, { useToasterStore } from 'react-hot-toast';
import { getCountryList } from '../api/global';
import { IMAGE_URL } from '../config';
import { uploadKyc } from '../api/wallet';
import { getReferrals, userDetails, userUpdateAvatar, userUpdateProfile, getReferralTransactions, userLogout, changePassword } from '../api/auth';
import { CurrencySymbol } from '../utils/constants';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email:'',
      name:'',
      mobile:'',
      dob:'',
      gender:'',
      document_name:'',
      document_number:'',
      document_type:'pan',
      document_front:'',
      document_back:'',
      document_front_base64:'',
      document_back_base64:'',
      document_front_file:'',
      document_back_file:'',
      avatar_image:'',
      avatar_base64:'',
      old_password:'',
      password:'',
      c_password:'',
    }
  }

 
  componentDidUpdate(){
    if(!this.state.email && this.props.userDetails){
      this.setState({
        name:this.props.userDetails?.name,
        email:this.props.userDetails?.email,
        mobile:this.props.userDetails?.mobile,
        dob:this.props.userDetails?.dob,
        gender:this.props.userDetails?.gender,
      })
    }
  }

  handleLogout = async()=>{
    this.setState({loading:true});
    let token = localStorage.getItem('token');
    await userLogout(token);
    localStorage.setItem('token','');
    this.setState({loading:false});
    this.props.navigate('/login');
  }

  doProfileUpdate = async()=>{
    try {   
      if(!this.state.name){
        toast.error('Name is required!!');
        return false;
      }
      // if(!this.state.mobile){
      //   toast.error('Phone number is required!!');
      //   return false;
      // }
      if(!this.state.dob){
        toast.error('DOB is required!!');
        return false;
      }
      // if(!this.state.gender){
      //   toast.error('Gender is required!!');
      //   return false;
      // }

      this.setState({loading:true});

      let token = localStorage.getItem('token');
      let input_data = {
        name:this.state.name,
        mobile:this.state.mobile,
        dob:this.state.dob,
        gender:this.state.gender
      }
      let upload_data = await userUpdateProfile(token,input_data);
      let msg = "Profile updated Successfully!";
      if(upload_data.status){
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          this.props.setUserDetails(user_response?.data);
          this.props.setLoadData(true);
        }
        msg = upload_data.msg;
        toast.success(msg);
      }else{
        msg = upload_data.msg;
        toast.error(msg);
      }
      this.setState({ loading: false});

    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString());
    }
  }

  doChangePassword = async()=>{
    try {   
      if(!this.state.old_password){
        toast.error('Old Password is required!!');
        return false;
      }
      if(!this.state.password){
        toast.error('Password is required!!');
        return false;
      }
      if(!this.state.c_password){
        toast.error('Confirm Password is required!!');
        return false;
      }
      if(this.state.password != this.state.c_password){
        toast.error('Password and confirm password is mismatch!!');
        return false;
      }

      this.setState({loading:true});

      let token = localStorage.getItem('token');
      let input_data = {
        old_password:this.state.old_password,
        password:this.state.password
      }
      let upload_data = await changePassword(token,input_data);
      let msg = "Password updated Successfully!";
      if(upload_data.status){
        this.setState({ loading: false});
        toast.success(msg);
        localStorage.setItem('token','');
        this.props.navigate('/login');
      }else{
        this.setState({ loading: false});
        msg = upload_data.msg;
        toast.error(msg);
      }
      

    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString());
    }
  }

  handleAvatar = async(e)=> {
    try {      
      console.log("file", e.target.files[0]);
      let file = e.target.files[0];
      if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
        this.setState({loading:true});
        let reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        reader.onload = async() => {
          console.log(reader.result)
          let token = localStorage.getItem('token');
          let input_data = {
            avatar:reader.result
          }
          let upload_data = await userUpdateAvatar(token,input_data);
          console.log(upload_data)
          let msg = "Avatar uploaded Successfully!";
          if(upload_data.status){
            let user_response = await userDetails(token);
            if(user_response.status){
              localStorage.setItem('user_details',JSON.stringify(user_response?.data));
              this.props.setUserDetails(user_response?.data);
              this.props.setLoadData(true);
            }
            msg = upload_data.msg;
            toast.success(msg);
          }else{
            msg = upload_data.msg;
            toast.error(msg);
          }
          this.setState({ loading: false});
        };        
      }else{
        toast.error('Invalid file type. Please select png/jpeg image.')
        return null
      }
    } catch (error) {
        
    }
  }

  handleFront = (e)=> {
    console.log("file", e.target.files[0]);
    let file = e.target.files[0];    
    if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      reader.onload = () => this.setState({document_front_base64:reader.result});
      
      this.setState({
        document_front:e.target.value,
        document_front_file:file
      });
    }else{
      toast.error('Invalid file type. Please select png/jpeg image.')
      return null
    }
  }

  handleBack = (e)=> {
    console.log("file", e.target.files[0]);
    let file = e.target.files[0];
    
    if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      reader.onload = () => this.setState({document_back_base64:reader.result});
      this.setState({
        document_back:e.target.value,
        document_back_file:file
      });
    }else{
      toast.error('Invalid file type. Please select png/jpeg image.')
      return null
    }
  }

  doUpload = async()=>{
    try {   
      if(!this.state.document_name){
        toast.error('Document name is required!!');
        return false;
      }
      if(!this.state.document_number){
        toast.error('Document number is required!!');
        return false;
      }
      if(!this.state.document_type){
        toast.error('Document type is required!!');
        return false;
      }
      if(!this.state.document_front){
        toast.error('Front image is required!!');
        return false;
      }
      if(!this.state.document_back){
        toast.error('Back image is required!!');
        return false;
      }

      if(this.state.document_front_file.type != 'image/jpeg'
        || this.state.document_front_file.type != 'image/png'){
        toast.error('Invalid file type. Please select png/jpeg image.')
        return null
      }

      if(this.state.document_back_file.type != 'image/jpeg' 
        || this.state.document_back_file.type != 'image/png'){
        toast.error('Invalid file type. Please select png/jpeg image.')
        return null
      }

      this.setState({loading:true});

      let token = localStorage.getItem('token');
      let input_data = {
        document_name:this.state.document_name,
        document_number:this.state.document_number,
        document_type:this.state.document_type,
        document_front:this.state.document_front_base64,
        document_back:this.state.document_back_base64
      }
      let upload_data = await uploadKyc(token,input_data);
      console.log(upload_data)
      let msg = "Document uploaded Successfully!";
      if(upload_data.status){
        msg = upload_data.msg;
        toast.success(msg);
      }else{
        msg = upload_data.msg;
        toast.error(msg);
      }
      this.setState({ loading: false});
      // this.fetchData();

    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.toString());
    }
  }

  

  render() {
    let this2 = this;
    // if(this.props.is_fetching){
    //   return null
    // }
    return (
      <Frame withHeader={false} withFooter={true}>
        {this.props.is_fetching || this.state.loading ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}
        <div className="header header-style2 fixed-top bg-menuDark d-flex align-items-center">
          <Link to={'/'} className="left back-btn"><i className="icon-left-btn"></i></Link>
          <h5 className="mb-0">My Profile</h5>
          <div className="d-flex align-items-center gap-8 header_r_p">
            <a href="#logout" data-bs-toggle="modal" className="icon_circle"><img src="/assets/img/user-logout.png" alt="" /></a>
          </div>
        </div>

        <div className="pt-55 pb-70">
          <div className="tf-container">
            <div className="text-center mb-20">
              <div className="profile_edit">
                {this.props.userDetails?.avatar?(
                  <img className='avatar_view' src={IMAGE_URL+this.props.userDetails?.avatar} alt="img" />
                ):(
                  <img className='avatar_view' src="/assets/img/avt2.png" alt="img" />
                )}
                <a onClick={()=>document.getElementById('avatar_file').click()} className='edit_avatar'>
                  <img className='edit-image-icon' src='/assets/img/image-editing.png' />
                </a>
                <input id='avatar_file' style={{display:'none'}} type="file" accept="image/png, image/jpeg" onChange={(e)=>this.handleAvatar(e)} />
                
              </div>
              <h5 className="mt-8 w-500">{this.props.userDetails?.username}</h5>
            </div>
            <ul className="mt-4 pb-16 user_details_list">
              <li data-bs-toggle="modal" data-bs-target="#Basic_details">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Basic details</p>
                  <div className="text-secondary d-flex gap-8 align-items-center"><i className="icon-arr-right text-secondary"></i></div>
                </a>
              </li>
              {/* <li data-bs-toggle="modal" data-bs-target="#Referrals">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Referral List</p>
                  <div className="text-secondary d-flex gap-8 align-items-center"><i className="icon-arr-right text-secondary"></i></div>
                </a>
              </li>
              <li data-bs-toggle="modal" data-bs-target="#ReferralIncome">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Level Income</p>
                  <div className="text-secondary d-flex gap-8 align-items-center"><i className="icon-arr-right text-secondary"></i></div>
                </a>
              </li> */}

              {/* <li data-bs-toggle="modal" data-bs-target="#Kyc">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">KYC</p>
                </a>
              </li>*/}

              <li>
                <Link to={'/wallet'} className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Wallet</p>
                </Link>
              </li>
              <li>
                <Link to={'/referal'} className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Referal Program</p>
                </Link>
              </li>
              <li data-bs-toggle="modal" data-bs-target="#changePassword">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Change Password</p>
                  <div className="text-secondary d-flex gap-8 align-items-center"><i className="icon-arr-right text-secondary"></i></div>
                </a>
              </li>
              
              {/* <li>
                <a href="#" className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">FAQ's</p>
                </a>
              </li> */}
              {/* <li data-bs-toggle="modal" data-bs-target="#Language">
                <a className="d-flex justify-content-between align-items-center">
                  <p className="text-button mb-0">Select Language</p>
                  <div className="text-secondary d-flex gap-8 align-items-center"><i className="icon-arr-right text-secondary"></i></div>
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="modal fade modalRight" id="Basic_details">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Basic details</h4>
              </div>
              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  <div className="mb-18">
                    <p className="mb-8 text-small"> Name</p>
                    <input className="input_area" type="text" placeholder="Name"
                      onChange={(e)=>this.setState({name:e.target.value})}
                      value={this.state.name}
                    />
                  </div>
                  <div className="mb-18">
                    <p className="mb-8 text-small"> Email</p>
                    <input className="input_area" type="text" placeholder="Email"
                      readOnly
                      // onChange={(e)=>this.setState({mobile:e.target.value})}
                      value={this.state.email}
                    />
                  </div>
                  <div className="mb-18">
                    <p className="mb-8 text-small"> DOB</p>
                    <input className="input_area" type="date" placeholder=""
                      readOnly
                      // onChange={(e)=>this.setState({dob:e.target.value})}
                      value={this.state.dob}
                    />
                  </div>
                  <button disabled={this.state.loading} onClick={this.doProfileUpdate} className="tf-btn primary md mt-20">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade modalRight" id="changePassword">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Change Password</h4>
              </div>
              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  <div className="mb-18">
                    <p className="mb-8 text-small"> Old Password</p>
                    <input className="input_area" type="password" placeholder="Old Password"
                      onChange={(e)=>this.setState({old_password:e.target.value})}
                      value={this.state.old_password}
                    />
                  </div>
                  <div className="mb-18">
                    <p className="mb-8 text-small"> Password</p>
                    <input className="input_area" type="password" placeholder="Password"
                      onChange={(e)=>this.setState({password:e.target.value})}
                      value={this.state.password}
                    />
                  </div>
                  <div className="mb-18">
                    <p className="mb-8 text-small"> Confirm Password</p>
                    <input className="input_area" type="password" placeholder="Confirm Password"
                      onChange={(e)=>this.setState({c_password:e.target.value})}
                      value={this.state.c_password}
                    />
                  </div>
                  <button disabled={this.state.loading} onClick={this.doChangePassword} className="tf-btn primary md mt-20">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade modalRight" id="Referrals">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Referral List</h4>
              </div>

              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  <ul className="" style={{marginTop:10}}>
                    {this.props.referral_list?.length>0?(
                      this.props.referral_list?.map(function(item,index){
                        return(
                          <li className='leauges_game_box' key={`tx${index}`}>
                            <p>Username: {item?.username}</p>
                            <p>Total Deposit: {parseFloat(item?.total_invest).toFixed(2)} {CurrencySymbol}</p>
                            <p>Total Commition: {parseFloat(item?.total_commission).toFixed(2)} {CurrencySymbol}</p>
                          </li>
                        )
                      })
                    ):(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade modalRight" id="ReferralIncome">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Level Income</h4>
              </div>

              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  <ul className="" style={{marginTop:10}}>
                    {this.props.referral_transactions?.length>0?(
                      this.props.referral_transactions?.map(function(item,index){
                        return(
                          <li className='leauges_game_box' key={`tx${index}`}>
                            <p>Username: {item?.username}</p>
                            <p>Level: {item?.level}</p>
                            <p>Amount: {parseFloat(item?.amount).toFixed(2)} {CurrencySymbol}</p>
                            <p>Bonus: {parseFloat(item?.bonus).toFixed(2)} {CurrencySymbol}</p>
                          </li>
                        )
                      })
                    ):(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="modal fade modalRight" id="Kyc">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Kyc Documents</h4>
              </div>

              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  {this.props.userDetails?.status_kyc==-1 || this.props.userDetails?.status_kyc==2 ? (
                    <>
                      <div className="mb-18">
                        <p className="mb-8 text-small"> Document Name</p>
                        <input className="input_area" type="text" placeholder="Document Name"
                          onChange={(e)=>this.setState({document_name:e.target.value})}
                          value={this.state.document_name}
                        />
                      </div>
                      <div className="mb-18">
                        <p className="mb-8 text-small"> Document Number</p>
                        <input className="input_area" type="text" placeholder="Document Number"
                          onChange={(e)=>this.setState({document_number:e.target.value})}
                          value={this.state.document_number}
                        />
                      </div>
                      <div className="mb-18">
                        <p className="mb-8 text-small"> Document Type</p>
                        <select className="input_area" onChange={(e)=>this.setState({document_type:e.target.value})}>
                          <option value={'pan'}> PAN </option>
                          <option value={'nid'}> NID </option>
                          <option value={'others'}> OTHERS </option>
                        </select>
                      </div>
                      <div className="mb-18">
                        <p className="mb-8 text-small"> Document Front Image</p>
                        <input className="input_area" type="file" placeholder="Document Type"
                          accept="image/png, image/jpeg"
                          onChange={(e)=>this.handleFront(e)}
                          value={this.state.document_front?.name}
                        />
                      </div>
                      <div className="mb-18">
                        <p className="mb-8 text-small"> Document Back Image</p>
                        <input className="input_area" type="file" placeholder="Document Type"
                          accept="image/png, image/jpeg"
                          onChange={(e)=>this.handleBack(e)}
                          value={this.state.document_back?.name}
                        />
                      </div>
                      <button disabled={this.state.loading} onClick={this.doUpload} className="tf-btn primary md mt-20">Upload</button>
                    </>
                  ):(null)}

                  {this.props.userDetails?.status_kyc==0?(
                    <div className="mb-18">
                      <p className="mb-8 text-small"> Your kyc documents uploade successfully. Please wait for verification.</p>
                    </div>
                  ):(null)}

                  {this.props.userDetails?.status_kyc==1?(
                    <div className="mb-18">
                      <p className="mb-8 text-small"> Your kyc documents has been verified.</p>
                    </div>
                  ):(null)}
                  
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="modal fade modalRight" id="Language">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="header fixed-top bg-surface header-style2 d-flex align-items-center">
                <span className="left" data-bs-dismiss="modal" aria-hidden="true"><i className="icon-left-btn"></i></span>
                <h4 className="mb-0">Select Language</h4>
              </div>

              <div className="overflow-auto pt-45 pb-16">
                <div className="tf-container">
                  <ul className="mt-4">
                      <li className="line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 active language-val">English <i className="icon icon-check-circle"></i> </div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Vietnamese <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Bahasa Indonesia <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">China <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Japanese <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Brasil <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Francais <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Deutsch <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Italiano <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4 line-bt"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">Polski <i className="icon icon-check-circle"></i></div></li>
                      <li className="mt-4"><div className="d-flex justify-content-between gap-8 text-large item-check-style2 language-val">India<i className="icon icon-check-circle"></i></div></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="logout">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="header header-style2 d-flex align-items-center">                
                <h4 className="mb-0">Do you want to logout?</h4>
              </div>
              <hr className="m-0" />

              <div className="modal-body">
                <div className='header header-style2 d-flex align-items-center'>
                  <a data-bs-dismiss="modal" className='btn_style1 border'>No</a>
                  <a data-bs-dismiss="modal" onClick={this.handleLogout} className='btn_style1'>Yes</a>
                </div>                
              </div>
            </div>

          </div>
        </div>

      </Frame >
    );
  }
}

function UserInfoHOC(props) {
  const [user_Details,setUserDetails] = useState(null);
  const [country_list,setCountryList] = useState([]);
  const [referral_list,setReferralList] = useState([]);
  const [referral_transactions,setReferralTransactions] = useState([]);
  const [is_fetching, setFetching] = useState(true);
  const [load_data, setLoadData] = useState(true);

  useEffect(() => {
    (async () => {
      if(is_fetching){
        setLoadData(false);
        let token = localStorage.getItem('token');
        let user_response = await userDetails(token);
        if(user_response.status){
          localStorage.setItem('user_details',JSON.stringify(user_response?.data));
          setUserDetails(user_response?.data);
        }          

        let country_data = await getCountryList(token);
        if(country_data?.status){
          setCountryList(country_data?.data);
        }

        let referral_list_ = await getReferrals(token);
        if(referral_list_?.status){
          setReferralList(referral_list_?.data);
        }

        let referral_trns = await getReferralTransactions(token);
        if(referral_trns?.status){
          setReferralTransactions(referral_trns?.data);
        }

        setFetching(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts 
    };

  }, [is_fetching,load_data]);

  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts.filter((t) => t.visible).filter((_, i) => i >= 1)
    .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const navigate = useNavigate();

  return <UserInfo
    is_fetching={is_fetching}
    userDetails={user_Details}
    country_list={country_list}
    referral_list={referral_list}
    referral_transactions={referral_transactions}
    setUserDetails={setUserDetails}
    setLoadData={setLoadData}
    navigate={navigate}
    {...props}
  />;
}

export default UserInfoHOC;