import React, { useEffect, useState, useContext } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Frame from "../components/frame";
import { useNavigate, useParams } from 'react-router-dom';
import { getGameList } from '../api/league';
import SingleGameHOC from '../components/my_single_game';
import SingleGameLiveHOC from '../components/my_single_game_live';
import SingleGameEndHOC from '../components/my_single_game_end';
import { userInfo } from '../context/AuthContext';
import { useChannel,  useConnectionStateListener } from 'ably/react';
import { userDetails } from '../api/auth';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import "react-datepicker/dist/react-datepicker.css";

class MyLeagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      day_range:[]
    }
  }

  componentDidMount = ()=>{
    this.Last7Days();
  }

  Last7Days = () => {
    var result = [];
    for (var i=0; i<7; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push( d )
    }
    result.reverse();
    this.setState({day_range:result});
    return(result.join(','));
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    return <span> Starts in {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  };

  render() {
    let this2 = this;
    return (
      <Frame withHeader={true} withFooter={true} current_page={'my_league'} game_type={this.props.game_type}>
        {this.props.is_fetching || this.props.load_data ? (
          <div className="loader-container">
            <div className="page_loader"></div>
          </div>
        ) : null}

        <div className="pt-110 pb-70">        
          <div className="tab-content pt-0 pb-10">
            <div  className={`mt-10 tab-pane fade ${this.props.game_type==1?'active show':''}`} id={`leagues1`} role="tabpanel">
              <ul className="menu-tab-v3 mt-14 nav-justified" role="tablist">
                <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#game1_Upcoming_game" role="presentation">
                  Upcoming
                </li>
                <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game1_Live_game">
                  Live
                </li>
                <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game1_Completed_game">
                  Completed
                </li>
              </ul>
              <div className="tab-content">
                <div className={`tab-pane fade ${this.props.game_type==1?'active show':''}`} id="game1_Upcoming_game" role="tabpanel">
                  <div className="tf-container">
                    {this.props.upcoming_game_list?.length>0?(                    
                      this.props.upcoming_game_list?.map(function(item,index){
                        return(
                          <SingleGameHOC 
                            key={`sgid${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}

                    {this.props.upcoming_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                    
                  </div>
                </div>
                <div className="tab-pane fade" id="game1_Live_game" role="tabpanel">
                  <div className="tf-container">                 
                    {this.props.live_game_list?.length>0?(
                      this.props.live_game_list?.map(function(item,index){
                        return(
                          <SingleGameLiveHOC 
                            key={`sglg${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}
                    {this.props.live_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                  </div>
                </div>
                <div className="tab-pane fade" id="game1_Completed_game" role="tabpanel">
                  <div className="tf-container">                  
                    <div className="row g-2 mb-16">
                      <div className='date_calender'>                      
                        <DatePicker
                          selected={this.props.game_date}
                          onChange={(date) => this.props.updateDate(date)}
                          minDate={this.props.joining_date}
                          maxDate={new Date()}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <ul className='date_box'>
                          {this.state.day_range.map(function(date){
                            let active_class = '';
                            let is_disable = false;
                            let disable_class = '';
                            if(moment(this2.props.game_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')){
                              active_class = 'date_active';
                            }
                            if (new Date(this2.props.joining_date).getTime() > date.getTime()) {
                              is_disable = true;
                              disable_class=' date_disable';
                            }

                            return (
                              <li onClick={()=>{
                                if(!is_disable){
                                  this2.props.updateDate(date);
                                }
                              }} className={active_class+disable_class}>{moment(date).format('DD')}</li>
                            )
                          })}
                        </ul>
                      </div>
                      
                      
                    </div>
                    {this.props.end_game_list?.length>0?(                    
                      this.props.end_game_list?.map(function(item,index){
                        return(
                          <SingleGameEndHOC 
                            key={`sgeg${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}
                    {this.props.end_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                  </div>
                </div>
              </div>

            </div>
            <div className={`tab-pane fade ${this.props.game_type==2?'active show':''}`} id="leagues2" role="tabpanel">
              <ul className="menu-tab-v3 pt-10 mt-14 nav-justified" role="tablist">
                <li className="nav-link active" data-bs-toggle="tab" data-bs-target="#game2_Upcoming_game" role="presentation">
                  Upcoming
                </li>
                <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game2_Live_game">
                  Live
                </li>
                <li className="nav-link" role="presentation" data-bs-toggle="tab" data-bs-target="#game2_Completed_game">
                  Completed
                </li>
              </ul>
              <div className="tab-content">
                <div className={`tab-pane fade ${this.props.game_type==2?'active show':''}`} id="game2_Upcoming_game" role="tabpanel">
                  <div className="tf-container">
                    {this.props.upcoming_game_list?.length>0?(                    
                      this.props.upcoming_game_list?.map(function(item,index){
                        return(
                          <SingleGameHOC 
                            key={`sgid${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}

                    {this.props.upcoming_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                    
                  </div>
                </div>
                <div className="tab-pane fade" id="game2_Live_game" role="tabpanel">
                  <div className="tf-container">                 
                    {this.props.live_game_list?.length>0?(
                      this.props.live_game_list?.map(function(item,index){
                        return(
                          <SingleGameLiveHOC 
                            key={`sglg${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}
                    {this.props.live_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                  </div>
                </div>
                <div className="tab-pane fade" id="game2_Completed_game" role="tabpanel">
                  <div className="tf-container">                  
                    <div className="row g-2 mb-16">
                      <div className='date_calender'>                      
                        <DatePicker
                          selected={this.props.game_date}
                          onChange={(date) => this.props.updateDate(date)}
                          minDate={this.props.joining_date}
                          maxDate={new Date()}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <ul className='date_box'>
                          {this.state.day_range.map(function(date){
                            let active_class = '';
                            let is_disable = false;
                            let disable_class = '';
                            if(moment(this2.props.game_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')){
                              active_class = 'date_active';
                            }
                            if (new Date(this2.props.joining_date).getTime() > date.getTime()) {
                              is_disable = true;
                              disable_class=' date_disable';
                            }

                            return (
                              <li onClick={()=>{
                                if(!is_disable){
                                  this2.props.updateDate(date);
                                }
                              }} className={active_class+disable_class}>{moment(date).format('DD')}</li>
                            )
                          })}
                        </ul>
                      </div>
                      
                      
                    </div>
                    {this.props.end_game_list?.length>0?(                    
                      this.props.end_game_list?.map(function(item,index){
                        return(
                          <SingleGameEndHOC 
                            key={`sgeg${item.id}${index}`}
                            game_data={item} index={index}
                            ethprice={this2.props.ethprice}
                            btcprice={this2.props.btcprice}
                            ethlastPrice={this2.props.ethlastPrice}
                            btclastPrice={this2.props.btclastPrice}
                            current_crypto={this2.props.current_crypto}
                            btcprice_change={this2.props.btcprice_change}
                            ethprice_change={this2.props.ethprice_change}
                            my_games={this2.props.my_games}
                          />
                        )
                      })
                    ):(null)}
                    {this.props.end_game_list?.length==0?(
                      <>
                        <img className='no-img' src="/assets/img/bear-new.png" alt="" />
                        <p className='text-center'>No Data Available!!</p>
                      </>
                    ):(null)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Frame >
    );
  }
}

function MyLeaguesHOC(props) {
  let { game_type } = useParams();
  const [live_game_list,setLiveGameList] = useState([]);
  const [user_up_game_list,setUpGameList] = useState([]);
  const [end_game_list,setEndGameList] = useState([]);
  const [is_fetching,setFetching] = useState(true);
  const [load_data,setLoadData] = useState(true);
  const [game_date,setGameDate] = useState(null);
  const [joining_date,setJoiningDate] = useState(null);
  const [user_details,setUserDetails] = useState(null);

  const { eth_price, btc_price, eth_lastPrice, btc_lastPrice,
     current_btc_price, current_eth_price,btcprice_change,ethprice_change,
     my_games, current_crypto, league_crypto_list,setLeagueCryptoList} = useContext(userInfo);

  const navigate = useNavigate();
  useEffect(() => {
    if(is_fetching){
      let current_date = Date.now();
      current_date = moment(current_date).format('YYYY-MM-DD');
      setGameDate(current_date);
      getGames(current_date);
    }
  }, [load_data,user_up_game_list,live_game_list,end_game_list]);

  const getGames = async(current_date='')=>{
    let token = localStorage.getItem('token');
    let user_response = await userDetails(token);
    if(user_response.status){
      let user = user_response?.data;
      setUserDetails(user);
      setJoiningDate(user?.created_at);
      let game_list = await getGameList(token,game_type,'',user?.id,current_date);
      let game_temp = game_list?.data?.upcoming?.data;
      setUpGameList(game_temp);
      
      let game_list_live = game_list?.data?.live?.data;
      setLiveGameList(game_list_live);

      let end_game_list = game_list?.data?.end?.data;
      setEndGameList(end_game_list);
      setFetching(false);
      setLoadData(false);
    }else{
      navigate('/login');
    }    
  }

  useConnectionStateListener('connected', () => {
    console.log('Connected to Ably!');
  });

  // Create a channel called 'get-started' and subscribe to all messages with the name 'first' using the useChannel hook
  useChannel('game-list', 'upcoming', (message) => {
    let list = JSON.parse(message?.data);
    if(list.length){
      let filter_list = list.filter(function (el) {
        return el.league_id==game_type;
      });
      let temp_data = [];
      filter_list.map(function(item){
        let found = my_games.find((element) => element.game_id == item.id);
        if(found){
          temp_data.push(temp_data);
        };
      })
      setUpGameList(temp_data);
    }
  });

  useChannel('game-list', 'live', (message) => {
    // let list = JSON.parse(message?.data);
    // if(list.length){
    //   let filter_list = list.filter(function (el) {
    //     return el.league_id==game_type;
    //   });
    //   let temp_data = [];
    //   filter_list.map(function(item){
    //     let found = my_games.find((element) => element.game_id == item.id);
    //     if(found){
    //       temp_data.push(temp_data);
    //     };
    //   })
    //   setLiveGameList(temp_data);
    // }
    getGames(game_date);
  });

  useChannel('game-list', 'end', (message) => {
    // let list = JSON.parse(message?.data);
    // if(list?.data?.length){
    //   let filter_list = list?.data?.filter(function (el) {
    //     return el.league_id==game_type;
    //   });
    //   let temp_data = [];
    //   filter_list.map(function(item){
    //     let found = my_games.find((element) => element.game_id == item.id);
    //     if(found){
    //       temp_data.push(temp_data);
    //     };
    //   })
    //   setEndGameList(temp_data);
    // }
    getGames(game_date);
  });

  useChannel('game-list', 'crypto-prize-pool', (message) => {
    let list = JSON.parse(message?.data);
    if(list.length>0){
      let dt = list[0];
      let temp_crypto = league_crypto_list.map(function(item){
        if(item.id == dt?.crypto_id){
          item.players = dt.players;
          item.prize_pool = dt.prize_pool;
          return item;
        }else{
          return item;
        }
      });
      setLeagueCryptoList(temp_crypto);
    }

  });

  const updateDate = (date)=>{
    setLoadData(true);
    date = moment(date).format('YYYY-MM-DD');
    setGameDate(date);
    getGames(date);
  }

  // console.log('messages',messages);

  return <MyLeagues    
    game_type={game_type}
    upcoming_game_list={user_up_game_list}
    live_game_list={live_game_list}
    end_game_list={end_game_list}
    is_fetching={is_fetching}
    ethprice={eth_price}
    btcprice={btc_price}
    ethlastPrice={eth_lastPrice}
    btclastPrice={btc_lastPrice}
    current_eth_price={current_eth_price}
    current_btc_price={current_btc_price}
    btcprice_change={btcprice_change}
    ethprice_change={ethprice_change}
    current_crypto={current_crypto}
    load_data={load_data}
    my_games={my_games}
    game_date={game_date}
    joining_date={joining_date}
    getGames={getGames}
    updateDate={updateDate}
    {...props}
  />;
}

export default MyLeaguesHOC;