import React, {useContext} from 'react';
import {Link, useLocation} from "react-router-dom";
import { userInfo } from '../context/AuthContext';

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="menubar-footer footer-fixed">
          <ul className="inner-bar mb-0">
            <li className={this.props.current_path=='/' ? "active" : ''}>
              <Link to="/"> 
                <i className="icon icon-home"></i>
                Home
              </Link>              
            </li>
            <li className={this.props.current_path.includes('/discover') ? "active":''}>
              <Link to={`/discover/1`}>
                <i className="icon icon-exchange"></i>
                Discover
              </Link>
            </li>
            <li className={this.props.current_path.includes('/referal') ? "active":''}>
              <Link to={`/referal`}>
                <i className="icon icon-share"></i>
                Referral
              </Link>
            </li>
            <li className={this.props.current_path=='/wallet' ? "active":''}>
              <Link to="/wallet">
                <i className="icon icon-wallet"></i>
                Wallet
              </Link>              
            </li>
            <li className={this.props.current_path.includes('/my_league') ? "active":''}>
              <Link to={`/my_league/1`}>
                <i className="icon icon-game-control"></i>
                My leagues
              </Link>              
            </li>
          </ul>
        </div>
      </>
    );
  }
}

function FooterHOC(props) {
  const { league_list } = useContext(userInfo);
  let location = useLocation();
  let current_path = location.pathname;

  return <Footer
    current_path={current_path}
    league_list={league_list}
    {...props}
  />;
}

export default FooterHOC;

// export default Footer;
//export default withTranslation()(Footer);