import React, {useEffect,useState} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import Home from "./pages/home";
import Wallet from "./pages/wallet";
import Referal from "./pages/referal-program";
import Analytics from "./pages/analytics";
import Discover from "./pages/discover";
import MyLeagues from "./pages/my_leagues";
import Leagues from "./pages/leagues";
import RulesInstructions from "./pages/rules_instructions";
import UserInfo from "./pages/user_info";
import TransactionHistory from "./pages/transaction_history";
import GameDetails from "./pages/game_details";
import Login from "./pages/Auth/login";
import Register from "./pages/Auth/register";
import ProtectedRoute from "./components/ProtectedRoute";
import PageNotFound from "./pages/not_found";
import { userInfo } from "./context/AuthContext";
import { getLeagueList, getCryptoList } from "./api/league";
import { getCurrentPrice, getSettings, getdemo } from "./api/global";
import * as Ably from 'ably';
import { AblyProvider, ChannelProvider, useChannel, useConnectionStateListener } from 'ably/react';
import { ABLY_TOKEN } from "./config";
import './App.css';
import { Exchange_Rate_Wsl_Url } from "./utils/constants";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getMyGames, userLogout } from "./api/auth";
import moment from "moment";
import toast from "react-hot-toast";
//import './i18n';

var ethlastPrice = null;
var btclastPrice = null;
var ethprice= null;
var btcprice= null;
var btcprice_change= 0;
var ethprice_change= 0;

// Connect to Ably using the AblyProvider component and your API key
const client = new Ably.Realtime({ key: ABLY_TOKEN,transportParams: { heartbeatInterval: 10000 } });

const App = () => {
  const [access_token,setAccessToken] = useState(null);
  const [userDetails,setUserDetails] = useState(null);
  const [league_list,setLeagueList] = useState([]);
  const [crypto_list,setCryptoList] = useState([]);
  const [league_crypto_list,setLeagueCryptoList] = useState([]);
  const [current_league,setCurrentLeague] = useState(null);
  const [current_crypto,setCurrentCrypto] = useState(null);
  const [current_btc_price,setCurrentBtcPrice] = useState(null);
  const [current_eth_price,setCurrentEthPrice] = useState(null);
  const [upcoming_game_list,setUpcomingGameList] = useState([]);
  const [my_games,setMyGames] = useState([]);
  const [settings,setSettings] = useState([]);
  const [is_auth,setIsAuth] = useState(true);
  useEffect(() => {
    let user_details = localStorage.getItem('user_details');
    let token = localStorage.getItem('token');
    setAccessToken(token);
    if(user_details){
      user_details = JSON.parse(user_details);
      setUserDetails(user_details);
    }
    if(!token){
      setIsAuth(false);
      // toast.error('Session out!!');
    }

    (async () => {
      if(access_token){
        getDefaultData(access_token);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };

  }, [access_token,is_auth]);

  const getDefaultData = async(token)=>{
    let league_list = await getLeagueList(token);
    if(!league_list?.status){
      setIsAuth(false);
    }else{
      let crypto_list = await getCryptoList(token);
      let settings_details = await getSettings(token);
      setLeagueList(league_list?.data);
      setCryptoList(crypto_list?.data);
      setSettings(settings_details?.data);
      if(league_list.status){
        if(league_list?.data[0]){
          setCurrentLeague(league_list?.data[0]);
          let league_crypto_list = await getCryptoList(token,league_list?.data[0]?.id);
          setLeagueCryptoList(league_crypto_list?.data);
          setCurrentCrypto(league_crypto_list?.data[0]);
          let c_btc_price = await getCurrentPrice('BTC');
          setCurrentBtcPrice(c_btc_price?.data?.rates?.USDT);
          let c_eth_price = await getCurrentPrice('ETH');
          setCurrentEthPrice(c_eth_price?.data?.rates?.USDT);
        }
        let current_date = Date.now();
        current_date = moment(current_date).format('YYYY-MM-DD');
        let my_game_list = await getMyGames(token,current_date);
        if(my_game_list.status){
          setMyGames(my_game_list?.data);
        }
      }
    }
    
  }

  const [eth_price,setethprice] = useState(current_eth_price);
  const [btc_price,setbtcprice] = useState(current_btc_price);
  const [eth_lastPrice,setethlastPrice] = useState(current_eth_price);
  const [btc_lastPrice,setbtclastPrice] = useState(current_btc_price);

  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(Exchange_Rate_Wsl_Url);
  const [messageHistory, setMessageHistory] = useState([]);
  
  const { lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
      let wsl_data = lastMessage?.data;
      if(wsl_data){
        wsl_data = JSON.parse(wsl_data);
        if (wsl_data.s=='ETHUSDT'){
          ethprice=parseFloat(wsl_data.p).toFixed(2);
        }
        else if(wsl_data.s=='BTCUSDT'){
          btcprice=parseFloat(wsl_data.p).toFixed(2);
        }
      }
      ethlastPrice=ethprice;
      btclastPrice=btcprice;
      setbtcprice(btcprice);
      setethprice(ethprice);
    }
  }, [lastMessage]);

  if(current_crypto?.code=='BTC'){
    // btcprice_change
    if(btc_price){
      let price_diff = parseFloat(btc_price)-parseFloat(current_crypto.last_day_price);
      btcprice_change = parseFloat(price_diff/parseFloat(btc_price)*100).toFixed(2);
    }
  }else if(current_crypto?.code=='ETH'){
    // ethprice_change
    let price_diff = parseFloat(eth_price)-parseFloat(current_crypto.last_day_price);
    ethprice_change = parseFloat(price_diff/parseFloat(eth_price)*100).toFixed(2);
  }

  // console.log('btcprice_change',btcprice_change)

//  console.log(lastMessage)

  return (
    <AblyProvider client={client}>
      <ChannelProvider channelName="game-list">
        <userInfo.Provider value={{
          userDetails,setUserDetails,
          league_list,setLeagueList,
          crypto_list,setCryptoList,
          current_league,setCurrentLeague,
          league_crypto_list,setLeagueCryptoList,
          eth_price,setethprice,
          btc_price,setbtcprice,
          eth_lastPrice,setethlastPrice,
          btc_lastPrice,setbtclastPrice,
          current_btc_price,setCurrentBtcPrice,
          current_eth_price,setCurrentEthPrice,
          current_crypto,setCurrentCrypto,
          upcoming_game_list,setUpcomingGameList,
          settings,setSettings,
          my_games,setMyGames,
          access_token,setAccessToken,
          is_auth,setIsAuth,
          btcprice_change,ethprice_change
        }}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/register/:sponsor?" element={<Register />}></Route>

            <Route path='/wallet' element={<ProtectedRoute is_auth={is_auth} Children={Wallet}/>}/>
            <Route path='/referal' element={<ProtectedRoute is_auth={is_auth} Children={Referal}/>}/>
            <Route path='/analytics' element={<ProtectedRoute is_auth={is_auth} Children={Analytics}/>}/>
            <Route path='/discover/:game_type?' element={<ProtectedRoute is_auth={is_auth} Children={Discover}/>}/>
            <Route path='/my_league/:game_type?' element={<ProtectedRoute is_auth={is_auth} Children={MyLeagues}/>}/>
            <Route path='/leagues/:league_id?/:crypto_id?' element={<ProtectedRoute is_auth={is_auth} Children={Leagues}/>}/>
            <Route path='/game_details/:game_id?' element={<ProtectedRoute is_auth={is_auth} Children={GameDetails}/>}/>
            <Route path='/user_info' element={<ProtectedRoute is_auth={is_auth} Children={UserInfo}/>}/>
            <Route path='/rules_instructions' element={<ProtectedRoute is_auth={is_auth} Children={RulesInstructions}/>}/>
            <Route path='/transaction_history' element={<ProtectedRoute is_auth={is_auth} Children={TransactionHistory}/>}/>
            <Route path='/' element={<ProtectedRoute is_auth={is_auth} Children={Home}/>}/>
            <Route path='/*' element={<ProtectedRoute is_auth={is_auth} Children={PageNotFound} />}/>
          </Routes>
        </BrowserRouter>
      </userInfo.Provider>
      </ChannelProvider>
    </AblyProvider>
  );
};

export default App;
